export const apiUrl = API_URL;

export const apiPrefix = 'v1/';

export const environment = NODE_ENV;

export const lookerUrl = LOOKER_URL;
export const paceEnvironment = PACE_ENVIRONMENT;
export const firebaseConfig = {
  apiKey: FIREBASE_API_KEY,
  appId: FIREBASE_APP_ID,
  authDomain: FIREBASE_AUTH_DOMAIN,
  databaseURL: FIREBASE_DATABASE_URL,
  messagingSenderId: FIREBASE_MESSAGING_SENDER_ID,
  projectId: FIREBASE_PROJECT_ID,
  storageBucket: FIREBASE_STORAGE_BUCKET
};
export const firebaseAppCheckSiteKey = FIREBASE_APP_CHECK_SITE_KEY;
export const tenantIds = {
  pace: TENANT_ID_PACE,
  alh: TENANT_ID_ALH
};
export const intercomApplicationId = INTERCOM_APPLICATION_ID;
export const launchDarklyId = LAUNCH_DARKLY_ID;
export const agGridLicenseKey = AG_GRID_LICENSE_KEY;
export const honeycomb = {
  collectorUrl: HONEYCOMB_COLLECTOR_URL,
  username: HONEYCOMB_COLLECTOR_USERNAME,
  password: HONEYCOMB_COLLECTOR_PASSWORD
};
