import moment, { Moment } from 'moment';
import { useEffect } from 'react';
import type { RestrictionsViewType } from '../pages/Restrictions/types';
import { GroupingMode as PlanningGroupingMode } from '../pages/Planning/types';
import { ChartGranularity, RevenueType } from '../__generated__/graphql';
import { EffectiveRestrictionView } from '../pages/Pricing/types';

export enum LocalStorageKey {
  Token = 'token',
  User = 'user',
  Tenant = 'tenant',
  UnclassifiedRatePlansBanner = 'pace:unclassified-rate-plans-banner',
  EffectiveRestrictionView = 'pace:effective-restrictions-view',
  AllRatePlansRestrictionsUnclassifiedBanner = 'pace:all-rate-plans-restrictions-unclassified-banner',
  RestrictionsUnsetRatePlansBanner = 'pace:restrictions-unset-rate-plans-banner',
  CalendarViewType = 'pace:calendar-view-type',
  RestrictionsViewType = 'pace:restrictions-view-type',
  RestrictionsRecommendationsShowType = 'pace:restrictions-recommendations-show-type',
  PortfolioFilters = 'pace:portfolio-page-filters',
  ShowForecastMonthlyWarningModal = 'pace:show-forecast-monthly-warning-modal',
  ShowForecastKpiEditWarningModal = 'pace:show-forecast-kpi-edit-warning-modal',
  ShowBudgetMonthlyWarningModal = 'pace:show-budget-monthly-warning-modal',
  ShowBudgetKpiEditWarningModal = 'pace:show-budget-kpi-edit-warning-modal',
  ShowDeletedSegmentToast = 'pace:show-deleted-segment-toast',
  ShowPAMoveToTrashModal = 'pace:show-pa-move-to-trash-modal',
  LockForecastWarningModal = 'pace:lock-forecast-warning-modal',
  LockBudgetWarningModal = 'pace:lock-budget-warning-modal',
  CurrentPropertyId = 'pace:current-property-id',
  ShowInventoryPricingDecimals = 'pace:inventory-show-pricing-decimals',
  PartialPricingChangesWarningModal = 'pace:show-partial-pricing-changes-warning-modal',
  InventoryGroupLevelActionWarningModal = 'pace:inventory-group-level-action-warning-modal',
  ForecastsGroupingMode = 'pace:forecasts-grouping-mode',
  CollapsedSideNavigation = 'pace:collapsed-side-navigation',
  GroupQuotationDataView = 'pace:group-quotation-data-view',
  GroupShowExclusiveTaxToggled = 'pace:show-exclusive-tax-toggled'
}

export type RatePlansBannerStatus = {
  snoozedOn: Moment;
};

type ShowPAMoveToTrashModal = {
  [userId: string]: boolean;
};

type ShowWarningModal = {
  [userId: string]: boolean;
};

type ShowDeletedSegmentToast = {
  [userId: string]: {
    [forecastId: string]: boolean;
  };
};

export type PortfolioFilters = {
  selectedAreas: string[];
  selectedBrands: string[];
  selectedPMSTypes: string[];
  selectedCountries: string[];
  selectedPropertyIds: string[];
  selectedAccommodationRevenue: RevenueType[];
  selectedGranularity: ChartGranularity;
  selectedDateRange: { startDate: string; endDate: string };
  showUserForecast: boolean;
  userId: string;
};

type User = {
  identity: number;
  email: string;
};

enum CalendarViewType {
  Month = 'month',
  Year = 'year',
  List = 'list'
}

const permanentStorageKeys = [
  LocalStorageKey.UnclassifiedRatePlansBanner,
  LocalStorageKey.RestrictionsUnsetRatePlansBanner,
  LocalStorageKey.CalendarViewType,
  LocalStorageKey.RestrictionsViewType,
  LocalStorageKey.RestrictionsRecommendationsShowType,
  LocalStorageKey.PortfolioFilters,
  LocalStorageKey.ShowForecastMonthlyWarningModal
];

class PaceLocalStorage {
  getToken() {
    return this.getValue(LocalStorageKey.Token);
  }

  setToken(token: string) {
    this.setValue(LocalStorageKey.Token, token);
  }

  removeToken() {
    this.removeValue(LocalStorageKey.Token);
  }

  getUser() {
    return this.parseJson(this.getValue(LocalStorageKey.User)) as
      | User
      | undefined;
  }

  setUser(user: User) {
    this.setValue(LocalStorageKey.User, JSON.stringify(user));
  }

  getTenant() {
    return this.getValue(LocalStorageKey.Tenant);
  }

  setTenant(tenant: string) {
    this.setValue(LocalStorageKey.Tenant, tenant);
  }

  getUnclassifiedRatePlansBannerStatus() {
    const result = this.parseJson(
      this.getValue(LocalStorageKey.UnclassifiedRatePlansBanner)
    );

    if (!result?.snoozedOn) {
      return undefined;
    }

    return {
      snoozedOn: moment(result.snoozedOn)
    } as RatePlansBannerStatus;
  }

  setUnclassifiedRatePlansBannerStatus(status: RatePlansBannerStatus) {
    this.setValue(
      LocalStorageKey.UnclassifiedRatePlansBanner,
      JSON.stringify({
        snoozedOn: status.snoozedOn.format()
      })
    );
  }

  getRestrictionsUnsetRatePlansBannerStatus() {
    const result = this.parseJson(
      this.getValue(LocalStorageKey.RestrictionsUnsetRatePlansBanner)
    );

    if (!result?.snoozedOn) {
      return undefined;
    }

    return {
      snoozedOn: moment(result.snoozedOn)
    } as RatePlansBannerStatus;
  }

  setRestrictionsUnsetRatePlansBannerStatus(status: RatePlansBannerStatus) {
    this.setValue(
      LocalStorageKey.RestrictionsUnsetRatePlansBanner,
      JSON.stringify({
        snoozedOn: status.snoozedOn.format()
      })
    );
  }

  getAllRatePlansRestrictionsUnclassifiedBanner() {
    const result = this.parseJson(
      this.getValue(LocalStorageKey.AllRatePlansRestrictionsUnclassifiedBanner)
    );

    if (!result?.snoozedOn) {
      return undefined;
    }

    return {
      snoozedOn: moment(result.snoozedOn)
    } as RatePlansBannerStatus;
  }

  getRestrictionView() {
    return this.getValue(LocalStorageKey.EffectiveRestrictionView) as
      | EffectiveRestrictionView
      | undefined;
  }

  setRestrictionView(view: EffectiveRestrictionView) {
    this.setValue(LocalStorageKey.EffectiveRestrictionView, view);
  }

  setAllRatePlansRestrictionsUnclassifiedBanner(status: RatePlansBannerStatus) {
    this.setValue(
      LocalStorageKey.AllRatePlansRestrictionsUnclassifiedBanner,
      JSON.stringify({
        snoozedOn: status.snoozedOn.format()
      })
    );
  }

  getCalendarViewType() {
    return this.getValue(LocalStorageKey.CalendarViewType) as
      | CalendarViewType
      | undefined;
  }

  setCalendarViewType(viewType: CalendarViewType) {
    this.setValue(LocalStorageKey.CalendarViewType, viewType);
  }

  getRestrictionsViewType() {
    return this.getValue(LocalStorageKey.RestrictionsViewType) as
      | RestrictionsViewType
      | undefined;
  }

  getPortfolioFilters(userId: string | undefined) {
    const storedFilters = this.parseJson(
      this.getValue(LocalStorageKey.PortfolioFilters)
    ) as PortfolioFilters;

    if (!storedFilters || !userId) {
      return undefined;
    }

    if (storedFilters.userId !== userId) {
      this.removeValue(LocalStorageKey.PortfolioFilters);
      return undefined;
    }

    return storedFilters;
  }

  setPortfolioFilters(portfolioFilters?: PortfolioFilters) {
    if (!portfolioFilters) {
      return this.removeValue(LocalStorageKey.PortfolioFilters);
    }

    this.setValue(
      LocalStorageKey.PortfolioFilters,
      JSON.stringify({
        selectedAreas: portfolioFilters.selectedAreas || [],
        selectedBrands: portfolioFilters.selectedBrands || [],
        selectedPMSTypes: portfolioFilters.selectedPMSTypes || [],
        selectedCountries: portfolioFilters.selectedCountries || [],
        selectedPropertyIds: portfolioFilters.selectedPropertyIds || [],
        selectedDateRange: portfolioFilters.selectedDateRange,
        selectedGranularity:
          portfolioFilters.selectedGranularity || ChartGranularity.Month,
        showUserForecast: portfolioFilters.showUserForecast || false,
        userId: portfolioFilters.userId || '',
        selectedAccommodationRevenue:
          portfolioFilters.selectedAccommodationRevenue || []
      })
    );
  }

  getShowPAMoveToTrashModal(userId?: string) {
    if (!userId) return false;
    const stored = this.parseJson(
      this.getValue(LocalStorageKey.ShowPAMoveToTrashModal)
    ) as ShowPAMoveToTrashModal | undefined;

    if (stored) {
      return stored[userId];
    }

    return false;
  }

  setShowPAMoveToTrashModal(userId?: string) {
    if (!userId) return;
    const stored = this.parseJson(
      this.getValue(LocalStorageKey.ShowPAMoveToTrashModal)
    ) as ShowPAMoveToTrashModal;

    this.setValue(
      LocalStorageKey.ShowPAMoveToTrashModal,
      JSON.stringify({
        ...stored,
        [userId]: true
      })
    );
  }

  getShowWarningModal(key: LocalStorageKey, userId?: string): boolean {
    if (!userId) return false;
    const stored = this.parseJson<ShowWarningModal>(this.getValue(key));
    return stored ? stored[userId] : false;
  }

  setShowWarningModal(key: LocalStorageKey, userId?: string): void {
    if (!userId) return;
    const stored = this.parseJson<ShowWarningModal>(this.getValue(key));
    this.setValue(
      key,
      JSON.stringify({
        ...stored,
        [userId]: true
      })
    );
  }

  getShowPlanningMonthlyWarningModal(
    entity: 'budgets' | 'forecasts' | undefined,
    userId?: string
  ) {
    if (entity === 'budgets') {
      return this.getShowWarningModal(
        LocalStorageKey.ShowBudgetMonthlyWarningModal,
        userId
      );
    }

    if (entity === 'forecasts') {
      return this.getShowWarningModal(
        LocalStorageKey.ShowForecastMonthlyWarningModal,
        userId
      );
    }
  }

  setShowPlanningMonthlyWarningModal(
    entity: 'budgets' | 'forecasts' | undefined,
    userId?: string
  ) {
    if (entity === 'budgets') {
      this.setShowWarningModal(
        LocalStorageKey.ShowBudgetMonthlyWarningModal,
        userId
      );
      return;
    }

    if (entity === 'forecasts') {
      this.setShowWarningModal(
        LocalStorageKey.ShowForecastMonthlyWarningModal,
        userId
      );
    }
  }

  getLockPlanningWarningModal(
    entity: 'budgets' | 'forecasts' | undefined,
    userId?: string
  ) {
    if (entity === 'budgets') {
      return this.getShowWarningModal(
        LocalStorageKey.LockBudgetWarningModal,
        userId
      );
    }

    if (entity === 'forecasts') {
      return this.getShowWarningModal(
        LocalStorageKey.LockForecastWarningModal,
        userId
      );
    }
  }

  setLockPlanningWarningModal(
    entity: 'budgets' | 'forecasts' | undefined,
    userId?: string
  ) {
    if (entity === 'budgets') {
      this.setShowWarningModal(LocalStorageKey.LockBudgetWarningModal, userId);
      return;
    }

    if (entity === 'forecasts') {
      this.setShowWarningModal(
        LocalStorageKey.LockForecastWarningModal,
        userId
      );
    }
  }

  getShowPlanningKpiEditWarningModal(
    entity: 'budgets' | 'forecasts' | undefined,
    userId?: string
  ) {
    if (entity === 'budgets') {
      return this.getShowWarningModal(
        LocalStorageKey.ShowBudgetKpiEditWarningModal,
        userId
      );
    }

    if (entity === 'forecasts') {
      return this.getShowWarningModal(
        LocalStorageKey.ShowForecastKpiEditWarningModal,
        userId
      );
    }
  }

  setShowPlanningKpiEditWarningModal(
    entity: 'budgets' | 'forecasts' | undefined,
    userId?: string
  ) {
    if (entity === 'budgets') {
      this.setShowWarningModal(
        LocalStorageKey.ShowBudgetKpiEditWarningModal,
        userId
      );
      return;
    }

    if (entity === 'forecasts') {
      this.setShowWarningModal(
        LocalStorageKey.ShowForecastKpiEditWarningModal,
        userId
      );
    }
  }

  getShowPartialPricingChangesWarningModal(userId?: string) {
    return this.getShowWarningModal(
      LocalStorageKey.PartialPricingChangesWarningModal,
      userId
    );
  }

  setShowPartialPricingChangesWarningModal(userId?: string) {
    this.setShowWarningModal(
      LocalStorageKey.PartialPricingChangesWarningModal,
      userId
    );
  }

  getShowInventoryGroupLevelActionWarningModal(userId?: string) {
    return this.getShowWarningModal(
      LocalStorageKey.InventoryGroupLevelActionWarningModal,
      userId
    );
  }

  setShowInventoryGroupLevelActionWarningModal(userId?: string) {
    this.setShowWarningModal(
      LocalStorageKey.InventoryGroupLevelActionWarningModal,
      userId
    );
  }

  getShowDeletedSegmentToast(userId: string, forecastId: string) {
    const stored = this.parseJson(
      this.getValue(LocalStorageKey.ShowDeletedSegmentToast)
    ) as ShowDeletedSegmentToast | undefined;

    if (stored) {
      return stored[userId][forecastId];
    }

    return false;
  }

  setShowDeletedSegmentToast(userId: string, forecastId: string) {
    const stored =
      (this.parseJson(
        this.getValue(LocalStorageKey.ShowDeletedSegmentToast)
      ) as ShowDeletedSegmentToast) || {};

    this.setValue(
      LocalStorageKey.ShowDeletedSegmentToast,
      JSON.stringify({
        ...stored,
        [userId]: {
          ...(stored[userId] || {}),
          [forecastId]: true
        }
      })
    );
  }

  setRestrictionsViewType(viewType: RestrictionsViewType) {
    this.setValue(LocalStorageKey.RestrictionsViewType, viewType);
  }

  getCurrentPropertyId() {
    return this.getValue(LocalStorageKey.CurrentPropertyId);
  }

  setCurrentPropertyId(id: string) {
    this.setValue(LocalStorageKey.CurrentPropertyId, id);
  }

  getPlanningGroupingMode(): PlanningGroupingMode | undefined {
    return this.getValue(LocalStorageKey.ForecastsGroupingMode) as
      | PlanningGroupingMode
      | undefined;
  }

  setPlanningGroupingMode(groupingMode: PlanningGroupingMode) {
    this.setValue(LocalStorageKey.ForecastsGroupingMode, groupingMode);
  }

  getCollapsedSideNavigation(): boolean {
    return this.getValue(LocalStorageKey.CollapsedSideNavigation) === 'true';
  }

  setCollapsedSideNavigation(collapsed: boolean) {
    this.setValue(LocalStorageKey.CollapsedSideNavigation, `${collapsed}`);
  }

  getGroupQuotationDataView(): 'table' | 'chart' {
    return (this.getValue(LocalStorageKey.GroupQuotationDataView) ||
      'chart') as 'table' | 'chart';
  }

  setGroupQuotationDataView(view: 'table' | 'chart') {
    this.setValue(LocalStorageKey.GroupQuotationDataView, view);
  }

  clear() {
    const keysToClear = Object.keys(localStorage).filter(
      key => !permanentStorageKeys.includes(key as LocalStorageKey)
    );

    for (const key of keysToClear) {
      this.removeValue(key);
    }
  }

  private parseJson<T extends Record<string, unknown>>(
    data: string | null | undefined
  ): T | undefined {
    try {
      if (data) {
        return JSON.parse(data);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }

    return undefined;
  }

  private setValue(key: string, value: string) {
    try {
      localStorage.setItem(key, value);
      window.dispatchEvent(new Event('storage'));
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('An error has occured while adding value to local storage');
    }
  }

  private getValue(key: string) {
    try {
      return localStorage.getItem(key);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(
        'An error has occured while getting value from local storage'
      );
    }

    return undefined;
  }

  private removeValue(key: string) {
    try {
      localStorage.removeItem(key);
      window.dispatchEvent(new Event('storage'));
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(
        'An error has occured while removing value from local storage'
      );
    }
  }
}

export const useOnLocalStorageChange = (callback: () => void) => {
  useEffect(() => {
    window.addEventListener('storage', callback);
    return () => window.removeEventListener('storage', callback);
  }, [callback]);
};

export default new PaceLocalStorage();
