import Base from './Base';
import * as urls from '../utils/urls';
import { AnalysisValues } from '../pages/LegacyGroups/components/GroupContext';

class GroupDisplacement extends Base {
  getAnalysis = async (payload: object, propertyId: string) =>
    await this.apiClient.post<AnalysisValues['displacedData']>(
      `${urls.groupDisplacement}?property_id=${propertyId}`,
      payload
    );
}

export default GroupDisplacement;
