import { useParams } from 'react-router-dom';
import { CalendarViewType } from '../types';

export const useCalendarViewParams = () => {
  const routeParams = useParams<{ view?: CalendarViewType }>();

  return {
    view: routeParams.view
  };
};
