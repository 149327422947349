// @ts-strict-ignore
import isNil from 'lodash/isNil';
import moment from 'moment';
import { dateRange } from './index';
import { forDates } from '../constants';
import { InventoryGroup, PriceManagementRow } from '../__generated__/graphql';

type PriceManagementDataAggregate = {
  dates: string[];
  property: {
    data: {
      occupancy: string;
      left_to_sell: string;
    }[];
  };
  inventory: {
    id: string;
    title: string;
    data: {
      left_to_sell: string;
    }[];
    children?: {
      id: string;
      title: string;
      data: {
        left_to_sell: string;
      }[];
    }[];
  }[];
};

export type InventoryData = {
  hotelroom_id: string;
  left_to_sell: string;
};

export type PriceManagementAPIResponse = {
  inventories: {
    hotelroom_id: string;
    title: string;
  }[];
  tableData: {
    date: string;
    'property-occupancy': string;
    'property-lts': string;
    inventories: InventoryData[];
  }[];
};

const transformInventoryData = (
  item: PriceManagementDataAggregate['inventory'][number],
  inventoryData: InventoryData[][]
) => {
  item.data.forEach((inventoryItem, index) => {
    const newInventory = {
      hotelroom_id: item.id,
      left_to_sell: inventoryItem.left_to_sell
    };

    const containsIndex = !isNil(inventoryData[index]);
    if (containsIndex) {
      inventoryData[index].push(newInventory);
    } else {
      inventoryData[index] = [newInventory];
    }
  });
};

const buildTableData = (
  result: PriceManagementAPIResponse,
  response: PriceManagementDataAggregate,
  inventoryData: InventoryData[][]
) => {
  const { dates, property } = response;

  dates.forEach((date, index) => {
    result.tableData.push({
      date,
      'property-occupancy': property.data[index].occupancy,
      'property-lts': property.data[index].left_to_sell,
      inventories: inventoryData[index]
    });
  });
};

const buildInventory = (
  item: PriceManagementDataAggregate['inventory'][number],
  result: PriceManagementAPIResponse
) => {
  result.inventories.push({
    hotelroom_id: item.id,
    title: item.title
  });
};

export function transformPriceManagementAPIResponse(
  response: PriceManagementDataAggregate
) {
  const result: PriceManagementAPIResponse = {
    inventories: [],
    tableData: []
  };

  const inventoryData: InventoryData[][] = [];

  response.inventory.forEach(item => {
    if (item.children) {
      item.children.forEach(child => {
        buildInventory(child, result);
        transformInventoryData(child, inventoryData);
      });
    } else {
      buildInventory(item, result);
      transformInventoryData(item, inventoryData);
    }
  });

  buildTableData(result, response, inventoryData);

  return result;
}

export const aggregatePriceManagementData = (
  { startDate, endDate }: { startDate: string; endDate: string },
  propertyId: string,
  [inventoryGroups, priceManagementRows]: [
    InventoryGroup[],
    PriceManagementRow[]
  ]
): PriceManagementDataAggregate => {
  const dates = dateRange(
    moment(startDate),
    moment(endDate),
    forDates.useISOFormat
  );

  const propertyValues = priceManagementRows.find(
    r => r.inventoryId === propertyId
  ).values;

  const propertyData = dates
    .map(date => propertyValues.find(v => v.date === date))
    .map(value => ({
      occupancy: value.occupancy.toFixed(),
      left_to_sell: value.leftToSell.toString()
    }));

  const generateInventoryData = (id: string) => {
    const pricingRow = priceManagementRows.find(r => r.inventoryId === id);
    if (!pricingRow) {
      return undefined;
    }

    const result = dates.map(date => {
      const analyticData = pricingRow.values.find(v => v.date === date);

      return {
        left_to_sell: analyticData.leftToSell.toString()
      };
    });
    return result;
  };

  const inventoryData = inventoryGroups.map(group => {
    const data = generateInventoryData(group.defaultInventoryId);

    if (data) {
      return {
        id: group._id,
        title: group.name,
        data
      };
    }

    return {
      id: group._id,
      title: group.name,
      data: generateInventoryData(group._id),
      children: group.children.map(child => ({
        id: child._id,
        title: child.name,
        data: generateInventoryData(child._id)
      }))
    };
  });

  return {
    dates,
    property: {
      data: propertyData
    },
    inventory: inventoryData
  };
};
