import {
  Checkbox as AntdCheckbox,
  CheckboxProps as AntdCheckboxProps
} from 'antd';
import styled from 'styled-components';
import {
  ColorGreen50,
  ColorGrey20,
  ColorGrey70,
  ColorWhiteDefault
} from '../../tokens/colors';

export type CheckboxProps = AntdCheckboxProps & {
  label?: string;
};

export const Checkbox = styled(AntdCheckbox)`
  span {
    color: ${ColorGrey20};
  }
  .ant-checkbox-inner {
    border-radius: 2px;
  }
  .ant-checkbox-indeterminate .ant-checkbox-inner {
    background-color: ${ColorGreen50};
    border-color: ${ColorGreen50};
  }
  .ant-checkbox-indeterminate .ant-checkbox-inner::after {
    width: 10px;
    height: 2px;
    background-color: ${ColorWhiteDefault};
  }
  .ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner::after {
    border-color: ${ColorGrey70};
  }
  .ant-checkbox-disabled.ant-checkbox-indeterminate .ant-checkbox-inner::after {
    background-color: ${ColorGrey70};
  }
  .ant-checkbox-disabled.ant-checkbox-indeterminate .ant-checkbox-inner {
    background-color: ${props => props.theme.colors.background.hover};
    border-color: ${ColorGrey70};
  }
`;
