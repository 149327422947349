import LogRocket from 'logrocket';

export const initialiseLogRocket = () => {
  LogRocket.init(LOGROCKET_KEY, {
    release: RELEASE,
    network: {
      requestSanitizer: request => {
        if (request.url.includes('opname=ChangePassword')) {
          try {
            if (request.body) {
              const parsedBody = JSON.parse(request.body);
              parsedBody.variables = {};
              request.body = JSON.stringify(parsedBody);
            }
          } catch (err) {
            request.body = '*****';
          }
        }

        if (request.url.includes('accounts:signInWithPassword')) {
          request.body = '*****';
        }

        return request;
      }
    }
  });
};
