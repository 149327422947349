enum SessionStorageKey {
  RestrictionsDurationWarningHidden = 'pace:do-not-show-restrictions-warning',
  AnalyticsExplorerHelpBoxes = 'pace:pa-explorer-help'
}

class PaceSessionStorage {
  getAnalyticsExplorerHelpBoxes(): Record<string, boolean> | undefined {
    const value = this.getValue(SessionStorageKey.AnalyticsExplorerHelpBoxes);
    if (!value) return undefined;
    try {
      const parsedValue = JSON.parse(value);
      return parsedValue;
    } catch (error) {
      return undefined;
    }
  }

  setAnalyticsExplorerHelpBoxes(value: Record<string, boolean>) {
    this.setValue(
      SessionStorageKey.AnalyticsExplorerHelpBoxes,
      JSON.stringify(value)
    );
  }

  getRestrictionsDurationWarningHidden() {
    const value = this.getValue(
      SessionStorageKey.RestrictionsDurationWarningHidden
    );

    return value === true.toString();
  }

  setRestrictionsDurationWarningHidden(value: boolean) {
    this.setValue(
      SessionStorageKey.RestrictionsDurationWarningHidden,
      value.toString()
    );
  }

  clear() {
    try {
      sessionStorage.clear();
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(
        'An error has occured while clearing values from session storage'
      );
    }
  }

  private setValue(key: string, value: string) {
    try {
      sessionStorage.setItem(key, value);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(
        'An error has occured while adding value to session storage'
      );
    }
  }

  private getValue(key: string) {
    try {
      return sessionStorage.getItem(key);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(
        'An error has occured while getting value from session storage'
      );
    }

    return undefined;
  }
}

export default new PaceSessionStorage();
