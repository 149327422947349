import { useApolloClient } from '@apollo/client';
import { useSetAtom } from 'jotai';
import { useCallback } from 'react';
import { currentPropertyIdAtom } from '../useCurrentProperty';

export const useClearClientCache = () => {
  const setCurrentPropertyId = useSetAtom(currentPropertyIdAtom);
  const client = useApolloClient();

  const handleClearCache = useCallback(() => {
    setCurrentPropertyId('');
    client.cache.reset();
  }, [client.cache, setCurrentPropertyId]);

  return handleClearCache;
};
