import styled from 'styled-components';
import { Form } from 'antd';
import { fromSmall } from '../../theme/sizes/mediaQueries';
import loginBackgroundImage from '../../theme/logos/flyr-logo-background.svg';
import logo from '../../theme/logos/flyr-logo-login.svg';

export const AccessibilityLabel = styled.label`
  display: none;
`;

export const AccessForm = styled(Form)`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 360px;
  width: 100%;
`;

export const ResponsiveContainer = styled.div`
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  height: 100vh;
  width: 100%;
  padding: 30px 40px 50px;
  z-index: 2;

  ${fromSmall(`
  width: 50%;
`)}
`;

export const BottomLink = styled.a`
  text-align: center;
`;

export const MarginContainer = styled.div`
  margin: 10px;
  width: 100%;
`;

export const FlyrLogo = styled.img.attrs({
  src: logo,
  alt: 'FLYR for Hospitality'
})`
  display: none;
  width: 240px;

  ${fromSmall(`
  display: block;
`)}
`;

export const iconStyle = {
  color: 'rgba(0, 0, 0, 0.25)'
};

export const LoginBackgroundImage = styled.img.attrs({
  src: loginBackgroundImage,
  alt: 'FLYR for Hospitality'
})`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 1;
  display: none;

  ${fromSmall(`
  display: block;
`)}
`;
