import decode from 'jwt-decode';
import moment from 'moment';
import {
  getAuth,
  signOut,
  signInWithEmailAndPassword,
  User,
  onAuthStateChanged
} from 'firebase/auth';
import { message } from 'antd';
import * as Sentry from '@sentry/browser';
import api from '../apiSingleton';
import { FirebaseError } from '../constants';
import PaceLocalStorage from './paceLocalStorage';
import PaceSessionStorage from './paceSessionStorage';

const isValidToken = (token: string | undefined | null) => {
  try {
    const tokenData = token && decode<{ exp: number }>(token);
    const tenMinutes = 600;
    return tokenData && tokenData.exp - moment().unix() - tenMinutes > 0;
  } catch (error) {
    return false;
  }
};

const getUser = () => {
  const currentUser = getAuth().currentUser;
  if (currentUser) {
    return currentUser;
  }

  return new Promise<User | null>(resolve => {
    const unsubscribe = onAuthStateChanged(getAuth(), user => {
      unsubscribe();
      resolve(user);
    });
  });
};

export const getToken = async () => {
  try {
    const token = PaceLocalStorage.getToken();
    if (isValidToken(token)) return token;

    const authenticatedUser = await getUser();
    const refreshedToken = await authenticatedUser?.getIdToken(true);
    if (refreshedToken) {
      PaceLocalStorage.setToken(refreshedToken);
    }

    return refreshedToken;
  } catch (error) {
    PaceLocalStorage.removeToken();
    Sentry.captureException(error);
    throw Error(error as string);
  }
};

export const logout = async () => {
  await signOut(getAuth());
  message.destroy();
  PaceLocalStorage.clear();
  PaceSessionStorage.clear();
};

export const login = async ({
  email,
  password
}: {
  email: string;
  password: string;
}) => {
  if (email && password) {
    const auth = getAuth();
    await signInWithEmailAndPassword(auth, email, password).catch(
      async error => {
        // when unable to sign in with email password with firebase try to login to pace auth
        // pace will create a user in firebase if the user exists with the given email / password
        if (error.code === FirebaseError.userNotFound) {
          await api.sessions.login({ email, password }).catch(error => {
            throw new Error(error.message || error.description);
          });
          // attempt login again after user has been created
          await signInWithEmailAndPassword(auth, email, password).catch(
            error => {
              throw new Error(error.message);
            }
          );
        } else {
          throw new Error(error.message);
        }
      }
    );
  }
};
