import { useEffect } from 'react';

const disabledClasses = ['ant-input-number-input'];

/**
 * @param expectedKey
 * @param callback wrap in a useCallback
 */
export const useKeyDown = (
  expectedKey: string,
  callback: (event: KeyboardEvent) => void
) => {
  useEffect(() => {
    const escFunction = (event: KeyboardEvent) => {
      if (disabledClasses.includes((event.target as HTMLElement).className)) {
        return;
      }

      if (event.key === expectedKey) {
        callback(event);
      }
    };

    document.addEventListener('keydown', escFunction);

    return () => {
      document.removeEventListener('keydown', escFunction);
    };
  }, [callback, expectedKey]);
};
