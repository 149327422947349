import React, { useEffect, useReducer } from 'react';
import { Provider as AtomProvider, createStore } from 'jotai';

import { useAuth } from './contexts/AuthContext';
import { AuthenticatedRoutes } from './Routes/AuthenticatedRoutes';
import { UnauthenticatedRoutes } from './Routes/UnauthenticatedRoutes';
import { useCurrentUser } from './hooks/useCurrentUser';
import { GlobalStoreContext } from './store';
import { UserIdentification } from './UserIdentification';

import './theme/css/reset.css';
import './theme/css/custom.scss';
import './theme/css/typography.css';
import './App.scss';

const App = () => {
  const { user } = useAuth();
  const currentUser = useCurrentUser({ skip: !user });
  const [globalStore, resetGlobalStore] = useReducer(
    () => createStore(),
    createStore()
  );

  useEffect(() => {
    if (!currentUser) {
      resetGlobalStore();
    }
  }, [currentUser]);

  return user ? (
    <>
      {currentUser && <UserIdentification currentUser={currentUser} />}
      <GlobalStoreContext.Provider value={globalStore}>
        <AtomProvider store={globalStore}>
          <AuthenticatedRoutes />
        </AtomProvider>
      </GlobalStoreContext.Provider>
    </>
  ) : (
    <UnauthenticatedRoutes />
  );
};

export default App;
