/**
 * Do not edit directly
 * Generated on Mon, 27 May 2024 06:47:55 GMT
 */

export const ColorAreaChartFill10 = "#d2ddd5ff";
export const ColorAreaChartFill11 = "#eff6faff";
export const ColorAreaChartFill12 = "#eccfcfff";
export const ColorAreaChartFill13 = "#f3efe3ff";
export const ColorAreaChartFill14 = "#ece0d5ff";
export const ColorAreaChartFill15 = "#eff2e7ff";
export const ColorAreaChartFill16 = "#e0eaefff";
export const ColorAreaChartFill17 = "#e0e0e0ff";
export const ColorAreaChartFill18 = "#ead6e2ff";
export const ColorAreaChartFill19 = "#fbefceff";
export const ColorAreaChartFill20 = "#d7ededff";
export const ColorAreaChartFill01 = "#d9dfe9ff";
export const ColorAreaChartFill02 = "#fdeae5ff";
export const ColorAreaChartFill03 = "#dfecdeff";
export const ColorAreaChartFill04 = "#fae9d5ff";
export const ColorAreaChartFill05 = "#fad1d0ff";
export const ColorAreaChartFill06 = "#f7f6dbff";
export const ColorAreaChartFill07 = "#eeeceaff";
export const ColorAreaChartFill08 = "#e7dfe7ff";
export const ColorAreaChartFill09 = "#f7f2f2ff";
export const ColorAreaChartStroke10 = "#1e542bff";
export const ColorAreaChartStroke11 = "#b1d3e6ff";
export const ColorAreaChartStroke12 = "#9f100fff";
export const ColorAreaChartStroke13 = "#c5af74ff";
export const ColorAreaChartStroke14 = "#a0622dff";
export const ColorAreaChartStroke15 = "#adbd89ff";
export const ColorAreaChartStroke16 = "#6394adff";
export const ColorAreaChartStroke17 = "#666666ff";
export const ColorAreaChartStroke18 = "#94346eff";
export const ColorAreaChartStroke19 = "#edad08ff";
export const ColorAreaChartStroke20 = "#38a6a5ff";
export const ColorAreaChartStroke01 = "#3f6091ff";
export const ColorAreaChartStroke02 = "#f6977cff";
export const ColorAreaChartStroke03 = "#60a15bff";
export const ColorAreaChartStroke04 = "#e6922fff";
export const ColorAreaChartStroke05 = "#e41816ff";
export const ColorAreaChartStroke06 = "#d7d44aff";
export const ColorAreaChartStroke07 = "#aaa296ff";
export const ColorAreaChartStroke08 = "#876088ff";
export const ColorAreaChartStroke09 = "#d8bebeff";
export const ColorLineChartStroke10 = "#44714eff";
export const ColorLineChartStroke11 = "#bedaeaff";
export const ColorLineChartStroke12 = "#af3837ff";
export const ColorLineChartStroke13 = "#cfbc8bff";
export const ColorLineChartStroke14 = "#b07c50ff";
export const ColorLineChartStroke15 = "#bbc89dff";
export const ColorLineChartStroke16 = "#7da6bbff";
export const ColorLineChartStroke17 = "#808080ff";
export const ColorLineChartStroke18 = "#a65686ff";
export const ColorLineChartStroke19 = "#f0bb31ff";
export const ColorLineChartStroke20 = "#59b5b4ff";
export const ColorLineChartStroke01 = "#5f7ba3ff";
export const ColorLineChartStroke02 = "#f7a892ff";
export const ColorLineChartStroke03 = "#7bb176ff";
export const ColorLineChartStroke04 = "#eaa452ff";
export const ColorLineChartStroke05 = "#e83f3dff";
export const ColorLineChartStroke06 = "#dedb68ff";
export const ColorLineChartStroke07 = "#b8b1a7ff";
export const ColorLineChartStroke08 = "#9b7b9cff";
export const ColorLineChartStroke09 = "#dec9c9ff";
export const ColorLineChartStrokeLight10 = "#b4c6b8ff";
export const ColorLineChartStrokeLight11 = "#e5f0f7ff";
export const ColorLineChartStrokeLight12 = "#dfafafff";
export const ColorLineChartStrokeLight13 = "#ece4d1ff";
export const ColorLineChartStrokeLight14 = "#dfcbb9ff";
export const ColorLineChartStrokeLight15 = "#e4e9d8ff";
export const ColorLineChartStrokeLight16 = "#cbdbe4ff";
export const ColorLineChartStrokeLight17 = "#ccccccff";
export const ColorLineChartStrokeLight18 = "#dbbbcfff";
export const ColorLineChartStrokeLight19 = "#f9e4adff";
export const ColorLineChartStrokeLight20 = "#bde1e1ff";
export const ColorLineChartStrokeLight01 = "#bfcadaff";
export const ColorLineChartStrokeLight02 = "#fcdcd3ff";
export const ColorLineChartStrokeLight03 = "#cae0c8ff";
export const ColorLineChartStrokeLight04 = "#f7dbbaff";
export const ColorLineChartStrokeLight05 = "#f6b2b1ff";
export const ColorLineChartStrokeLight06 = "#f2f1c3ff";
export const ColorLineChartStrokeLight07 = "#e3e0dcff";
export const ColorLineChartStrokeLight08 = "#d7cad7ff";
export const ColorLineChartStrokeLight09 = "#f2e9e9ff";
export const ColorWhiteDefault = "#ffffffff";
export const ColorBlackDefault = "#000000ff";
export const ColorGreen5 = "#00291bff";
export const ColorGreen10 = "#00291bff";
export const ColorGreen20 = "#00493aff";
export const ColorGreen30 = "#008d71ff";
export const ColorGreen40 = "#1abc9cff";
export const ColorGreen50 = "#00a484ff";
export const ColorGreen60 = "#3cc9a9ff";
export const ColorGreen70 = "#47f8d5ff";
export const ColorGreen80 = "#b3ddd4ff";
export const ColorGreen90 = "#b5f4e8ff";
export const ColorGreen100 = "#e8f8f5ff";
export const ColorBlue10 = "#04465bff";
export const ColorBlue20 = "#0a84acff";
export const ColorBlue30 = "#0b45d8ff";
export const ColorBlue40 = "#265ef2ff";
export const ColorBlue50 = "#5682f5ff";
export const ColorBlue55 = "#7e91bfff";
export const ColorBlue60 = "#40a9ffff";
export const ColorBlue70 = "#17bdf3ff";
export const ColorBlue80 = "#68d4f7ff";
export const ColorBlue90 = "#a8e6faff";
export const ColorBlue95 = "#c8d8ffff";
export const ColorBlue100 = "#f0f5ffff";
export const ColorLime20 = "#389e0dff";
export const ColorLime50 = "#52c41aff";
export const ColorLime100 = "#f6ffedff";
export const ColorGrey10 = "#ffffffff";
export const ColorGrey20 = "#363d46ff";
export const ColorGrey30 = "#595959ff";
export const ColorGrey40 = "#ffffffff";
export const ColorGrey50 = "#969696ff";
export const ColorGrey60 = "#bdbdbdff";
export const ColorGrey70 = "#d9d9d9ff";
export const ColorGrey80 = "#e8e8e8ff";
export const ColorGrey90 = "#eff2f5ff";
export const ColorGrey100 = "#fafafaff";
export const ColorRed30 = "#f7cfd3ff";
export const ColorRed50 = "#ff333bff";
export const ColorRed60 = "#ff5368ff";
export const ColorRed70 = "#ff8695ff";
export const ColorYellow20 = "#cca367ff";
export const ColorYellow30 = "#fca21fff";
export const ColorYellow50 = "#fdb750ff";
export const ColorYellow60 = "#ffcc81ff";
export const ColorYellow70 = "#ffd666ff";
export const ColorYellow80 = "#ffe58fff";
export const ColorYellow90 = "#fffbe6ff";
export const ColorYellow100 = "#fff4e5cc";
export const ColorCadetBlue10 = "#d9dfe9ff";
export const ColorCadetBlue20 = "#bfcadaff";
export const ColorCadetBlue30 = "#9fb0c8ff";
export const ColorCadetBlue40 = "#7f95b6ff";
export const ColorCadetBlue50 = "#5f7ba3ff";
export const ColorCadetBlue60 = "#3f6091ff";
export const ColorCadetBlue70 = "#355079ff";
export const ColorCadetBlue80 = "#2a4061ff";
export const ColorCadetBlue90 = "#203049ff";
export const ColorCadetBlue100 = "#152030ff";
export const ColorSalmon10 = "#fdeae5ff";
export const ColorSalmon20 = "#fcdcd3ff";
export const ColorSalmon30 = "#facbbdff";
export const ColorSalmon40 = "#f9baa8ff";
export const ColorSalmon50 = "#f7a892ff";
export const ColorSalmon60 = "#f6977cff";
export const ColorSalmon70 = "#cd7e67ff";
export const ColorSalmon80 = "#a46553ff";
export const ColorSalmon90 = "#7b4c3eff";
export const ColorSalmon100 = "#523229ff";
export const ColorSeaGreen10 = "#dfecdeff";
export const ColorSeaGreen20 = "#cae0c8ff";
export const ColorSeaGreen30 = "#afd0adff";
export const ColorSeaGreen40 = "#95c092ff";
export const ColorSeaGreen50 = "#7bb176ff";
export const ColorSeaGreen60 = "#60a15bff";
export const ColorSeaGreen70 = "#50864cff";
export const ColorSeaGreen80 = "#406b3dff";
export const ColorSeaGreen90 = "#30512eff";
export const ColorSeaGreen100 = "#20361eff";
export const ColorTeal30 = "#067779ff";
export const ColorTeal40 = "#09b6baff";
export const ColorTeal50 = "#6ab4acff";
export const ColorLilac100 = "#ede9f3ff";
export const ColorOrange10 = "#fae9d5ff";
export const ColorOrange20 = "#f7dbbaff";
export const ColorOrange30 = "#f2c897ff";
export const ColorOrange40 = "#eeb674ff";
export const ColorOrange50 = "#eaa452ff";
export const ColorOrange60 = "#e6922fff";
export const ColorOrange70 = "#c07a27ff";
export const ColorOrange80 = "#99611fff";
export const ColorOrange90 = "#734918ff";
export const ColorOrange100 = "#4d3110ff";
export const ColorBrightRed10 = "#fad1d0ff";
export const ColorBrightRed20 = "#f6b2b1ff";
export const ColorBrightRed30 = "#f18b8aff";
export const ColorBrightRed40 = "#ed6564ff";
export const ColorBrightRed50 = "#e83f3dff";
export const ColorBrightRed60 = "#e41816ff";
export const ColorBrightRed70 = "#be1412ff";
export const ColorBrightRed80 = "#98100fff";
export const ColorBrightRed90 = "#720c0bff";
export const ColorBrightRed100 = "#4c0807ff";
export const ColorYellowGreen10 = "#f7f6dbff";
export const ColorYellowGreen20 = "#f2f1c3ff";
export const ColorYellowGreen30 = "#ebe9a4ff";
export const ColorYellowGreen40 = "#e4e286ff";
export const ColorYellowGreen50 = "#dedb68ff";
export const ColorYellowGreen60 = "#d7d44aff";
export const ColorYellowGreen70 = "#b3b13eff";
export const ColorYellowGreen80 = "#8f8d31ff";
export const ColorYellowGreen90 = "#6c6a25ff";
export const ColorYellowGreen100 = "#484719ff";
export const ColorSmoke10 = "#eeeceaff";
export const ColorSmoke20 = "#e3e0dcff";
export const ColorSmoke30 = "#d4d0caff";
export const ColorSmoke40 = "#c6c1b9ff";
export const ColorSmoke50 = "#b8b1a7ff";
export const ColorSmoke60 = "#aaa296ff";
export const ColorSmoke70 = "#8e877dff";
export const ColorSmoke80 = "#716c64ff";
export const ColorSmoke90 = "#55514bff";
export const ColorSmoke100 = "#393632ff";
export const ColorPurple10 = "#e7dfe7ff";
export const ColorPurple20 = "#d7cad7ff";
export const ColorPurple30 = "#c3afc3ff";
export const ColorPurple40 = "#af95b0ff";
export const ColorPurple50 = "#9b7b9cff";
export const ColorPurple60 = "#876088ff";
export const ColorPurple70 = "#715071ff";
export const ColorPurple80 = "#5a405bff";
export const ColorPurple90 = "#443044ff";
export const ColorPurple100 = "#2d202dff";
export const ColorRosyBrown10 = "#f7f2f2ff";
export const ColorRosyBrown20 = "#f2e9e9ff";
export const ColorRosyBrown30 = "#ecdfdfff";
export const ColorRosyBrown40 = "#e5d4d4ff";
export const ColorRosyBrown50 = "#dec9c9ff";
export const ColorRosyBrown60 = "#d8bebeff";
export const ColorRosyBrown70 = "#b49e9eff";
export const ColorRosyBrown80 = "#907f7fff";
export const ColorRosyBrown90 = "#6c5f5fff";
export const ColorRosyBrown100 = "#483f3fff";
export const ColorDarkGreen10 = "#d2ddd5ff";
export const ColorDarkGreen20 = "#b4c6b8ff";
export const ColorDarkGreen30 = "#8ea995ff";
export const ColorDarkGreen40 = "#698d72ff";
export const ColorDarkGreen50 = "#44714eff";
export const ColorDarkGreen60 = "#1e542bff";
export const ColorDarkGreen70 = "#194624ff";
export const ColorDarkGreen80 = "#14381dff";
export const ColorDarkGreen90 = "#0f2a16ff";
export const ColorDarkGreen100 = "#0a1c0eff";
export const ColorPowderBlue10 = "#eff6faff";
export const ColorPowderBlue20 = "#e5f0f7ff";
export const ColorPowderBlue30 = "#d8e9f2ff";
export const ColorPowderBlue40 = "#cbe2eeff";
export const ColorPowderBlue50 = "#bedaeaff";
export const ColorPowderBlue60 = "#b1d3e6ff";
export const ColorPowderBlue70 = "#93b0c0ff";
export const ColorPowderBlue80 = "#768d99ff";
export const ColorPowderBlue90 = "#596a73ff";
export const ColorPowderBlue100 = "#3b464dff";
export const ColorFireBrick10 = "#eccfcfff";
export const ColorFireBrick20 = "#dfafafff";
export const ColorFireBrick30 = "#cf8787ff";
export const ColorFireBrick40 = "#bf605fff";
export const ColorFireBrick50 = "#af3837ff";
export const ColorFireBrick60 = "#9f100fff";
export const ColorFireBrick70 = "#6a0b0aff";
export const ColorFireBrick80 = "#6a0b0aff";
export const ColorFireBrick90 = "#500808ff";
export const ColorFireBrick100 = "#350505ff";
export const ColorDarkKhaki10 = "#f3efe3ff";
export const ColorDarkKhaki20 = "#ece4d1ff";
export const ColorDarkKhaki30 = "#e2d7b9ff";
export const ColorDarkKhaki40 = "#d8caa2ff";
export const ColorDarkKhaki50 = "#cfbc8bff";
export const ColorDarkKhaki60 = "#c5af74ff";
export const ColorDarkKhaki70 = "#a49261ff";
export const ColorDarkKhaki80 = "#83754dff";
export const ColorDarkKhaki90 = "#63583aff";
export const ColorDarkKhaki100 = "#423a27ff";
export const ColorChocolate10 = "#ece0d5ff";
export const ColorChocolate20 = "#dfcbb9ff";
export const ColorChocolate30 = "#cfb096ff";
export const ColorChocolate40 = "#c09673ff";
export const ColorChocolate50 = "#b07c50ff";
export const ColorChocolate60 = "#a0622dff";
export const ColorChocolate70 = "#855226ff";
export const ColorChocolate80 = "#6b411eff";
export const ColorChocolate90 = "#503117ff";
export const ColorChocolate100 = "#35210fff";
export const ColorOliveGreen10 = "#eff2e7ff";
export const ColorOliveGreen20 = "#e4e9d8ff";
export const ColorOliveGreen30 = "#d6dec4ff";
export const ColorOliveGreen40 = "#c8d3b0ff";
export const ColorOliveGreen50 = "#bbc89dff";
export const ColorOliveGreen60 = "#adbd89ff";
export const ColorOliveGreen70 = "#909d72ff";
export const ColorOliveGreen80 = "#737e5bff";
export const ColorOliveGreen90 = "#575f45ff";
export const ColorOliveGreen100 = "#3a3f2eff";
export const ColorSteelBlue10 = "#e0eaefff";
export const ColorSteelBlue20 = "#cbdbe4ff";
export const ColorSteelBlue30 = "#b1c9d6ff";
export const ColorSteelBlue40 = "#97b8c8ff";
export const ColorSteelBlue50 = "#7da6bbff";
export const ColorSteelBlue60 = "#6394adff";
export const ColorSteelBlue70 = "#537b90ff";
export const ColorSteelBlue80 = "#426373ff";
export const ColorSteelBlue90 = "#324a57ff";
export const ColorSteelBlue100 = "#21313aff";
export const ColorDimGray10 = "#e0e0e0ff";
export const ColorDimGray20 = "#ccccccff";
export const ColorDimGray30 = "#b3b3b3ff";
export const ColorDimGray40 = "#999999ff";
export const ColorDimGray50 = "#808080ff";
export const ColorDimGray60 = "#666666ff";
export const ColorDimGray70 = "#555555ff";
export const ColorDimGray80 = "#444444ff";
export const ColorDimGray90 = "#333333ff";
export const ColorDimGray100 = "#222222ff";
export const ColorVioletRed10 = "#ead6e2ff";
export const ColorVioletRed20 = "#dbbbcfff";
export const ColorVioletRed30 = "#c999b6ff";
export const ColorVioletRed40 = "#b8789eff";
export const ColorVioletRed50 = "#a65686ff";
export const ColorVioletRed60 = "#94346eff";
export const ColorVioletRed70 = "#7b2b5cff";
export const ColorVioletRed80 = "#632349ff";
export const ColorVioletRed90 = "#4a1a37ff";
export const ColorVioletRed100 = "#311125ff";
export const ColorGold10 = "#fbefceff";
export const ColorGold20 = "#f9e4adff";
export const ColorGold30 = "#f6d683ff";
export const ColorGold40 = "#f3c85aff";
export const ColorGold50 = "#f0bb31ff";
export const ColorGold60 = "#edad08ff";
export const ColorGold70 = "#c59007ff";
export const ColorGold80 = "#9e7305ff";
export const ColorGold90 = "#775704ff";
export const ColorGold100 = "#2f2302ff";
export const ColorDarkTurquoise10 = "#d7ededff";
export const ColorDarkTurquoise20 = "#bde1e1ff";
export const ColorDarkTurquoise30 = "#9bd2d2ff";
export const ColorDarkTurquoise40 = "#7ac4c3ff";
export const ColorDarkTurquoise50 = "#59b5b4ff";
export const ColorDarkTurquoise60 = "#38a6a5ff";
export const ColorDarkTurquoise70 = "#2f8a89ff";
export const ColorDarkTurquoise80 = "#1c5353ff";
export const ColorDarkTurquoise90 = "#133737ff";
export const ColorDarkTurquoise100 = "#2f2302ff";