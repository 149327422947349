// @ts-strict-ignore
import * as Sentry from '@sentry/browser';
import { getApp, initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { initializeAppCheck, ReCaptchaV3Provider } from 'firebase/app-check';
import LogRocket from 'logrocket';
import { firebaseConfig, firebaseAppCheckSiteKey } from '../config';

export const firebase = initializeApp(firebaseConfig);

export const firestore = getFirestore();

if (firebaseAppCheckSiteKey) {
  initializeAppCheck(getApp(), {
    provider: new ReCaptchaV3Provider(firebaseAppCheckSiteKey),
    isTokenAutoRefreshEnabled: true
  });
}

export const auth = getAuth();

onAuthStateChanged(auth, async user => {
  if (user) {
    const {
      claims: { email, identity }
    } = await user.getIdTokenResult();
    Sentry.setUser({
      id: identity as string,
      email: email as string
    });
    LogRocket.identify(identity as string, {
      email: user.email
    });
  }
});
