type NestedValueOf<ObjectType extends object> = {
  [Key in keyof ObjectType]: ObjectType[Key] extends object
    ? NestedValueOf<ObjectType[Key]>
    : ObjectType[Key];
}[keyof ObjectType];

export type TrackingEventNamesType = NestedValueOf<typeof TrackingEventNames>;

export const TrackingEventNames = {
  dataEnrichment: {
    singleAssign: 'data-enrichment_single-assign',
    nothingSelected: 'data-enrichment_nothing-selected',
    bulkAssign: 'data-enrichment_bulk-assign',
    filterChanged: 'data-enrichment_filter-changed'
  },
  priceAlertsDrawer: {
    open: 'price-alerts-drawer_open',
    filterApplied: 'price-alerts-drawer_filter-applied',
    openQuickView: 'price-alerts-drawer_open-quick-view',
    openPriceSettingEditDrawer:
      'price-alerts-drawer_open-price-setting-edit-drawer',
    openPriceSettingCreateDrawer:
      'price-alerts-drawer_open-price-setting-create-drawer',
    clickUpdateRatePlans: 'price-alerts-drawer_click-update-rate-plans',
    hideUpdateRatePlans: 'price-alerts-drawer_hide-update-rate-plans'
  },
  pricingPage: {
    selectTab: 'pricing-page_select-tab',
    shortcutClick: 'pricing-page_shortcut-click',
    openQuickView: 'pricing-page_open-quick-view',
    showDecimals: 'pricing-page_show-decimals',
    changeDateRange: 'pricing-page_change-date-range',
    bulkPriceUpdateDrawer: {
      open: 'pricing-page_bulk-price-update-drawer_open',
      update: 'pricing-page_bulk-price-update-drawer_update'
    },
    newTacticalRestrictionDrawer: {
      open: 'pricing-page_new-tactical-restriction-drawer_open'
    },
    footer: {
      changeFrozenState: 'pricing-page_footer_change-frozen-state',
      updateRecommendedPrices: 'pricing-page_footer_update-recommended-prices',
      updateOptimalPrices: 'pricing-page_footer_update-optimal-prices'
    },
    segmentedButtonClick: 'pricing-page_segmented-button-click',
    segmentedTooltipHover: 'pricing-page_segmented-tooltip-hover',
    segmentedTooltipTimeOpened: 'pricing-page_segmented-tooltip-time-opened',
    restrictionsViewPicker: 'pricing-page_restrictions-view-picker',
    questionCircleIconClick: 'pricing-page_question-circle-icon-click',
    legendTooltipTimeOpened: 'pricing-page_legend-tooltip-time-opened',
    marketRatesFilter: {
      chartConfigurationRequested: 'market-rates_chart_configuration_requested',
      roomTypeFilterClicked: 'market-rates_room_type_filter_clicked',
      rateTypeFilterClicked: 'market-rates_rate_type_filter_clicked',
      distributionChannelFilterClicked:
        'market-rates_distribution_channel_filter_clicked',
      mealTypeFilterClicked: 'market-rates_meal_type_filter_clicked',
      LOSFilterClicked: 'market-rates_LOS_filter_clicked',
      numberOfGuestsFilterClicked:
        'market-rates_number_of_guests_filter_clicked',
      roomTypeFilterChanged: 'market-rates_room_type_filter_changed',
      rateTypeFilterChanged: 'market-rates_rate_type_filter_changed',
      distributionChannelFilterChanged:
        'market-rates_distribution_channel_filter_changed',
      mealTypeFilterChanged: 'market-rates_meal_type_filter_changed',
      LOSFilterChanged: 'market-rates_LOS_filter_changed',
      numberOfGuestsFilterChanged:
        'market-rates_number_of_guests_filter_changed',
      roomTypeFilterTypedValue: 'market-rates_room_type_filter_typed_value',
      rateTypeFilterTypedValue: 'market-rates_rate_type_filter_typed_value',
      mealTypeFilterTypedValue: 'market-rates_meal_type_filter_typed_value',
      distributionChannelFilterTypedValue:
        'market-rates_distribution_channel_filter_typed_value',
      LOSFilterTypedValue: 'market-rates_LOS_filter_typed_value',
      numberOfGuestsFilterTypedValue:
        'market-rates_number_of_guests_filter_typed_value',
      shoppedRateClicked: 'market-rates_shopped_rate_clicked',
      sellingRateClicked: 'market-rates_selling_rate_clicked'
    },
    restrictionsRecommendationsDrawer: {
      singleRecommendationApplied:
        'pricing-page_restrictions-recommendations-drawer_single-recommendation-applied',
      multipleRecommendationsApplied:
        'pricing-page_restrictions-recommendations-drawer_multiple-recommendations-applied',
      quickViewOpen:
        'pricing-page_restrictions-recommendations-drawer_quick-view-open',
      drawerOpen:
        'pricing-page_restrictions-recommendations-drawer_drawer-open',
      filterApplied:
        'pricing-page_restrictions-recommendations-drawer_filter-applied',
      clickClassifyRatePlans:
        'pricing-page_restrictions-recommendations-drawer_click-classify-rate-plans',
      hideSomeUnclassifiedRatePlansBanner:
        'pricing-page_restrictions-recommendations-drawer_hide-some-unclassified-rate-plans-banner',
      hideAllUnclassifiedRatePlansBanner:
        'pricing-page_restrictions-recommendations-drawer_hide-all-unclassified-rate-plans-banner'
    },
    restrictionsRecommendationsPopover: {
      recommendationApplied:
        'pricing-page_restrictions-recommendations-popover_recommendation-applied'
    }
  },
  quickView: {
    restoreOptimalPrice: 'quick-view_restore-optimal-price',
    acceptOptimalPrice: 'quick-view_accept-optimal-price',
    acceptRecommendedPrice: 'quick-view_accept-recommended-price',
    setManualPrice: 'quick-view_set-manual-price',
    changeFrozenState: 'quick-view_change-frozen-state',
    switchDay: 'quick-view_switch-day',
    switchInventory: 'quick-view_switch-inventory',
    selectTab: 'quick-view_select-tab',
    selectRoomType: 'quick-view_select-room-type'
  },
  propertySelector: {
    selectProperty: 'property-selector_select-property'
  },
  calendarEntriesPopover: {
    singleEntryOpen: 'calendar-entries-popover_single-entry-open',
    actionClick: 'calendar-entries-popover_action-click'
  },
  calendarPage: {
    viewSelect: 'calendar-page_view-select',
    periodArrowClick: 'calendar-page_period-arrow-click',
    createDrawerOpen: 'calendar-page_create-drawer-open',
    shortcutClick: 'calendar-page_shortcut-click',
    dragAndDrop: 'calendar-page_drag-and-drop',
    listView: {
      actionClick: 'calendar-page_list-view_action-click',
      filterUpdate: 'calendar-page_list-view_filter-update'
    }
  },
  pageView: 'page-view',
  pageNotFound: 'page-not-found',
  loginPage: {
    loggedIn: 'logged_in'
  },
  calendarEntryDrawer: {
    create: 'calendar-entry-drawer_create',
    update: 'calendar-entry-drawer_update'
  },
  restrictions: {
    recommendationQuickView: 'restriction_recommendation_quickview',
    recommendationAccepted: 'restriction_recommendation_accepted',
    recommendationIgnored: 'restriction_recommendation_ignored',
    create: 'restriction_create',
    timeframeSelect: 'restriction_timeframe-select',
    edit: 'restriction_edit',
    delete: 'restriction_delete',
    bulkDelete: 'restriction_bulk-delete',
    search: 'restriction_search',
    filterRatePlan: 'restriction_filter-rate-plan',
    filterInventory: 'restriction_filter-inventory',
    filterRestrictionsToShow: 'restriction_filter-restrictions-to-show',
    dateRangePick: 'restriction_date-range-pick',
    groupBy: 'restriction_group-by',
    itemTooltipOpen: 'restriction_item-tooltip-open',
    itemTooltipToggle: 'restriction_item-tooltip-toggle',
    itemTooltipEdit: 'restriction_item-tooltip-edit',
    createType: 'restriction_create-type'
  },
  ratePlans: {
    globalSearch: 'rate-plans_global-search'
  },
  inventoryPage: {
    showDecimals: 'inventory-page_show-decimals'
  },
  planning: {
    forecasts: {
      versionsDrawerOpen: 'planning_forecasts_versions-drawer-open',
      versionCopy: 'planning_forecasts_version-copy',
      saveMonthly: 'planning_forecasts_save-monthly',
      saveSegmented: 'planning_forecasts_save-segmented',
      cancelMonthly: 'planning_forecasts_cancel-monthly',
      cancelSegmented: 'planning_forecasts_cancel-segmented',
      copyPasteTable: 'planning_forecasts_copy-paste-table',
      editInlineKpis: 'planning_forecasts_edit-inline-kpis',
      clearInlineKpis: 'planning_forecasts_clear-inline-kpis',
      lockVersion: 'planning_forecasts_lock-version',
      cancelLockVersion: 'planning_forecasts_cancel-lock-version',
      redistribute: 'planning_forecasts_redistribute',
      saveInlineKpis: 'planning_forecasts_save-inline-kpis',
      changeDateRangeMonthly: 'planning_forecasts_change-date-range-monthly',
      changeDateRangeSegmented:
        'planning_forecasts_change-date-range-segmented',
      changeProperty: 'planning_forecasts_change-property',
      navigateToSegmentsView: 'planning_forecasts_navigate-to-segments-view',
      editKPIsSegmentsView: 'planning_forecasts_edit-kpis-segments-view',
      saveInlineEdit: 'planning_forecasts_save-inline-edit',
      noSegmentsScreen: 'planning_forecasts_no-segments-screen',
      populateClicked: 'planning_forecasts_populate-clicked',
      populateSystemClicked: 'planning_forecasts_populate-system-clicked',
      populateSuggestClicked: 'planning_forecasts_populate-suggest-clicked',
      monthlyPopulated: 'planning_forecasts_monthly-populated',
      monthlyLocked: 'planning_forecasts_monthly-locked',
      segmentedPopulated: 'planning_forecasts_segmented-populated',
      editSegmented: 'planning_forecasts_edit-segmented'
    }
  },
  insights: {
    navigation: {
      rootSelect: 'insights-navigation-select-level-1',
      groupSelect: 'insights-navigation-select-level-2',
      level2SearchSelect: 'insights-navigation-select-level-2-search',
      itemSelect: 'insights-navigation-select-level-3',
      backSelect: 'insights-navigation-go-back',
      toasterSelect: 'insights-navigation-toaster-redirect'
    },
    search: {
      input: 'insights-search-input-character',
      filter: 'insights-search-filter-change',
      itemSelect: 'insights-search-item-select',
      close: 'insights-search-close'
    },
    misc: {
      options: 'insights-options-menu-open',
      duplicate: 'insights-duplicate-select',
      collapse: 'insights-menu-collapse-change',
      saveFavorites: 'insights-favorite-save',
      removeFavorites: 'insights-favorite-remove',
      setAsHome: 'insights-set-as-home-select'
    }
  },
  rateShopperPage: {
    marketRateConfigurationAccessed:
      'rate-shopper-page_market_rate_configuration_accessed',
    backToConfigurationClicked:
      'rate-shopper-page_back_to_configuration_clicked',
    propertyMappings: {
      marketRateConfigurationRequested:
        'rate-shopper-page_market_rate_configuration_requested',
      propertyMappingClicked: 'rate-shopper-page_property_mapping-clicked',
      propertyOrderingChanged: 'rate-shopper-page_property_ordering_changed',
      rateShopperEnabled: 'rate-shopper-page_rate_shopper_enabled',
      rateShopperDisabled: 'rate-shopper-page_rate_shopper_disabled',
      propertyIdFieldClicked: 'rate-shopper-page_property_id_field_clicked',
      propertyIdFilled: 'rate-shopper-page_property_id_filled',
      propertyIdInlineErrorAppeared:
        'rate-shopper-page_property_id_inline_error_appeared',
      saveButtonClicked:
        'rate-shopper-page_save_button_clicked_property_mapping'
    },
    inventoryTypeMapping: {
      inventoryMapppingClicked: 'rate-shopper-page_inventory_mappping_clicked',
      saveButtonClicked:
        'rate-shopper-page_save_button_clicked_inventory_type_mapping',
      autoSuggestButtonClicked: 'rate-shopper-page_auto_suggest_button_clicked',
      autoSuggestTooltipClicked:
        'rate-shopper-page_auto_suggest_tooltip_clicked',
      autoSuggestErrorEncountered:
        'rate-shopper-page_auto_suggest_error_encountered',
      inventoryTypeDropdownManuallyFilled:
        'rate-shopper-page_inventory_mapping_manual_selection'
    },
    defaultFilters: {
      defaultFiltersClicked: 'rate-shopper-page_default_filters_clicked',
      saveButtonClicked:
        'rate-shopper-page_save_button_clicked_default_filters',
      propertyColumnTitleClicked:
        'rate-shopper-page_property_column_title_clicked',
      roomTypeColumnTitleClicked:
        'rate-shopper-page_room_type_column_title_clicked',
      rateTypeColumnTitleClicked:
        'rate-shopper-page_rate_type_column_title_clicked',
      mealTypeColumnTitleClicked:
        'rate-shopper-page_meal_type_column_title_clicked',
      distributionChannelColumnTitleClicked:
        'rate-shopper-page_distribution_channel_column_title_clicked',
      defaultRateColumnTitleClicked:
        'rate-shopper-page_default_rate_column_title_clicked',
      propertyCheckboxSelected: 'rate-shopper-page_property_checkbox_selected',
      bulkEditButtonClicked: 'rate-shopper-page_bulk_edit_button_clicked',
      bulkEditApplyButtonClicked:
        'rate-shopper-page_bulk_edit_apply_button_clicked'
    }
  },
  groups: {
    groupCreated: 'groups_group-created',
    groupEdited: 'groups_group-edited',
    groupNameUpdated: 'groups_group-name-updated',
    groupRequoted: 'groups_group-requoted',
    negotiationRatesEdited: 'groups_negotiation-rates-edited'
  }
} as const;
