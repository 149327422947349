import { gql } from '@apollo/client';
import { useGetCurrentUserQuery } from '../__generated__/graphql';

gql`
  query GetCurrentUser {
    propertyGroup {
      id
      name
    }
    currentUser {
      id
      email
      role
      isDemoUser
    }
  }
`;

export const useCurrentUser = ({ skip }: { skip?: boolean } = {}) => {
  const { data } = useGetCurrentUserQuery({ skip });

  if (!data?.currentUser || !data?.propertyGroup) {
    return undefined;
  }

  return {
    id: data.currentUser.id,
    email: data.currentUser.email,
    role: data.currentUser.role,
    isDemoUser: data.currentUser.isDemoUser,
    propertyGroupId: data.propertyGroup.id,
    propertyGroupName: data.propertyGroup.name
  };
};
