import {
  ColorAreaChartFill01,
  ColorAreaChartFill02,
  ColorAreaChartFill03,
  ColorAreaChartFill04,
  ColorAreaChartFill05,
  ColorAreaChartFill06,
  ColorAreaChartFill07,
  ColorAreaChartFill08,
  ColorAreaChartFill09,
  ColorAreaChartFill10,
  ColorAreaChartFill11,
  ColorAreaChartFill12,
  ColorAreaChartFill13,
  ColorAreaChartFill14,
  ColorAreaChartFill15,
  ColorAreaChartFill16,
  ColorAreaChartFill17,
  ColorAreaChartFill18,
  ColorAreaChartFill19,
  ColorAreaChartFill20,
  ColorAreaChartStroke01,
  ColorAreaChartStroke02,
  ColorAreaChartStroke03,
  ColorAreaChartStroke04,
  ColorAreaChartStroke05,
  ColorAreaChartStroke06,
  ColorAreaChartStroke07,
  ColorAreaChartStroke08,
  ColorAreaChartStroke09,
  ColorAreaChartStroke10,
  ColorAreaChartStroke11,
  ColorAreaChartStroke12,
  ColorAreaChartStroke13,
  ColorAreaChartStroke14,
  ColorAreaChartStroke15,
  ColorAreaChartStroke16,
  ColorAreaChartStroke17,
  ColorAreaChartStroke18,
  ColorAreaChartStroke19,
  ColorAreaChartStroke20,
  ColorLineChartStroke01,
  ColorLineChartStroke02,
  ColorLineChartStroke03,
  ColorLineChartStroke04,
  ColorLineChartStroke05,
  ColorLineChartStroke06,
  ColorLineChartStroke07,
  ColorLineChartStroke08,
  ColorLineChartStroke09,
  ColorLineChartStroke10,
  ColorLineChartStroke11,
  ColorLineChartStroke12,
  ColorLineChartStroke13,
  ColorLineChartStroke14,
  ColorLineChartStroke15,
  ColorLineChartStroke16,
  ColorLineChartStroke17,
  ColorLineChartStroke18,
  ColorLineChartStroke19,
  ColorLineChartStroke20,
  ColorLineChartStrokeLight01,
  ColorLineChartStrokeLight02,
  ColorLineChartStrokeLight03,
  ColorLineChartStrokeLight04,
  ColorLineChartStrokeLight05,
  ColorLineChartStrokeLight06,
  ColorLineChartStrokeLight07,
  ColorLineChartStrokeLight08,
  ColorLineChartStrokeLight09,
  ColorLineChartStrokeLight10,
  ColorLineChartStrokeLight11,
  ColorLineChartStrokeLight12,
  ColorLineChartStrokeLight13,
  ColorLineChartStrokeLight14,
  ColorLineChartStrokeLight15,
  ColorLineChartStrokeLight16,
  ColorLineChartStrokeLight17,
  ColorLineChartStrokeLight18,
  ColorLineChartStrokeLight19,
  ColorLineChartStrokeLight20
} from '../tokens/colors';

export const chartColors = {
  areaChart: {
    fill: {
      '01': ColorAreaChartFill01,
      '02': ColorAreaChartFill02,
      '03': ColorAreaChartFill03,
      '04': ColorAreaChartFill04,
      '05': ColorAreaChartFill05,
      '06': ColorAreaChartFill06,
      '07': ColorAreaChartFill07,
      '08': ColorAreaChartFill08,
      '09': ColorAreaChartFill09,
      '10': ColorAreaChartFill10,
      '11': ColorAreaChartFill11,
      '12': ColorAreaChartFill12,
      '13': ColorAreaChartFill13,
      '14': ColorAreaChartFill14,
      '15': ColorAreaChartFill15,
      '16': ColorAreaChartFill16,
      '17': ColorAreaChartFill17,
      '18': ColorAreaChartFill18,
      '19': ColorAreaChartFill19,
      '20': ColorAreaChartFill20
    },
    stroke: {
      '01': ColorAreaChartStroke01,
      '02': ColorAreaChartStroke02,
      '03': ColorAreaChartStroke03,
      '04': ColorAreaChartStroke04,
      '05': ColorAreaChartStroke05,
      '06': ColorAreaChartStroke06,
      '07': ColorAreaChartStroke07,
      '08': ColorAreaChartStroke08,
      '09': ColorAreaChartStroke09,
      '10': ColorAreaChartStroke10,
      '11': ColorAreaChartStroke11,
      '12': ColorAreaChartStroke12,
      '13': ColorAreaChartStroke13,
      '14': ColorAreaChartStroke14,
      '15': ColorAreaChartStroke15,
      '16': ColorAreaChartStroke16,
      '17': ColorAreaChartStroke17,
      '18': ColorAreaChartStroke18,
      '19': ColorAreaChartStroke19,
      '20': ColorAreaChartStroke20
    }
  },
  lineChart: {
    stroke: {
      '01': ColorLineChartStroke01,
      '02': ColorLineChartStroke02,
      '03': ColorLineChartStroke03,
      '04': ColorLineChartStroke04,
      '05': ColorLineChartStroke05,
      '06': ColorLineChartStroke06,
      '07': ColorLineChartStroke07,
      '08': ColorLineChartStroke08,
      '09': ColorLineChartStroke09,
      '10': ColorLineChartStroke10,
      '11': ColorLineChartStroke11,
      '12': ColorLineChartStroke12,
      '13': ColorLineChartStroke13,
      '14': ColorLineChartStroke14,
      '15': ColorLineChartStroke15,
      '16': ColorLineChartStroke16,
      '17': ColorLineChartStroke17,
      '18': ColorLineChartStroke18,
      '19': ColorLineChartStroke19,
      '20': ColorLineChartStroke20
    },
    strokeLight: {
      '01': ColorLineChartStrokeLight01,
      '02': ColorLineChartStrokeLight02,
      '03': ColorLineChartStrokeLight03,
      '04': ColorLineChartStrokeLight04,
      '05': ColorLineChartStrokeLight05,
      '06': ColorLineChartStrokeLight06,
      '07': ColorLineChartStrokeLight07,
      '08': ColorLineChartStrokeLight08,
      '09': ColorLineChartStrokeLight09,
      '10': ColorLineChartStrokeLight10,
      '11': ColorLineChartStrokeLight11,
      '12': ColorLineChartStrokeLight12,
      '13': ColorLineChartStrokeLight13,
      '14': ColorLineChartStrokeLight14,
      '15': ColorLineChartStrokeLight15,
      '16': ColorLineChartStrokeLight16,
      '17': ColorLineChartStrokeLight17,
      '18': ColorLineChartStrokeLight18,
      '19': ColorLineChartStrokeLight19,
      '20': ColorLineChartStrokeLight20
    }
  }
};
