import { getProp } from './index';

export const generateErrorMsg = (config = {}) =>
  `Prix Dashboard -> ${getProp(
    config,
    'method',
    ' Unknown method '
  )} # ${getProp(config, 'msg', ' Missing message ')}. Metadata: ${getProp(
    config,
    'metaData',
    ' Missing '
  )}`;
