import { gql } from '@apollo/client';
import { useSsoSettingsQuery } from '../../../__generated__/graphql';

gql`
  query SSOSettings($slug: String!) {
    ssoSettings(slug: $slug) {
      tenantId
      providers {
        __typename
        ... on SAMLProvider {
          id
          label
        }
        ... on OAuthProvider {
          id
          label
        }
        ... on GoogleProvider {
          id
          label
        }
      }
    }
  }
`;

export const useSSOSettings = (slug: string) => {
  const { data, loading, error } = useSsoSettingsQuery({
    variables: { slug }
  });

  const settings = data?.ssoSettings;

  return {
    settings,
    loading,
    error
  };
};
