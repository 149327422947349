import { FieldMergeFunction, StoreObject, TypePolicies } from '@apollo/client';
import keyBy from 'lodash/keyBy';

export const priceManagementRowMergeFn: FieldMergeFunction = (
  existing: StoreObject[] | undefined,
  incoming: StoreObject[] | undefined,
  { readField }
) => {
  if (!existing) {
    return incoming;
  }

  const existingValuesMap = keyBy(existing, object =>
    readField<string>('date', object)
  );

  for (const object of incoming || []) {
    const date = readField<string>('date', object);
    if (date) {
      existingValuesMap[date] = object;
    }
  }

  return Object.values(existingValuesMap);
};

export const possibleTypes = {
  CalendarEntryBase: [
    'DefaultPriceSetting',
    'SeasonalPriceSetting',
    'EventPriceSetting',
    'PropertyClosure',
    'Note'
  ],
  CalendarEntry: [
    'DefaultPriceSetting',
    'SeasonalPriceSetting',
    'EventPriceSetting',
    'PropertyClosure',
    'Note'
  ],
  CalendarItem: [
    'DefaultPriceSetting',
    'SeasonalPriceSetting',
    'EventPriceSetting',
    'PropertyClosure',
    'Note'
  ],
  ManualForecastRow: [
    'ManualForecastBySegment',
    'ManualForecastByMonth',
    'ManualForecastUpsertResponse'
  ]
};

export const typePolicies: TypePolicies = {
  Enrichment: {
    keyFields: ['id', 'entity', 'category']
  },
  ChartValue: {
    keyFields: false
  },
  ChartSeries: {
    keyFields: false
  },
  Currency: {
    keyFields: false
  },
  PropertyPerformance: {
    keyFields: false
  },
  PortfolioPerformanceTile: {
    keyFields: false
  },
  PerformanceBreakdown: {
    keyFields: false
  },
  Chart: {
    keyFields: false
  },
  PickupSummary: {
    keyFields: false
  },
  ManualForecastByMonth: {
    keyFields: ['date', 'forecastId']
  },
  ManualForecastBySegment: {
    keyFields: ['date', 'segmentId', 'forecastId']
  },
  PropertyGroup: {
    fields: {
      properties: {
        merge(existing, incoming) {
          return incoming;
        }
      },
      allGroupQuotes: {
        merge(_, incoming) {
          return incoming;
        }
      }
    }
  },
  PropertyMetrics: {
    keyFields: ['propertyId', 'startDate', 'endDate', 'dateComparisonMode']
  },
  TimeSeries: {
    merge: (current, incoming) => ({
      ...current,
      ...incoming
    })
  },
  Property: {
    fields: {
      searchCompanyProfiles: {
        merge: (_, incoming) => incoming
      },
      companyProfile: {
        merge: (_, incoming) => incoming
      },
      marketRatesConfig: {
        merge: (current, incoming) => ({
          ...current,
          ...incoming
        })
      }
    }
  },
  PriceManagementRow: {
    fields: {
      values: {
        merge: priceManagementRowMergeFn
      }
    }
  }
};
