import styled from 'styled-components';
import { ColorGreen20 } from '../../theme/tokens/colors';
import { fontWeights } from '../../theme/fonts/typography';

export const StyledErrorBoundary = styled.div`
  background: ${ColorGreen20};
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  div {
    width: 300px;
  }

  h1,
  h2,
  p,
  button {
    color: #fff;
  }

  h1 {
    font-size: 61px;
    margin-bottom: 32px;
    line-height: 1;
    font-weight: ${fontWeights.black};
  }

  h2 {
    font-size: 20px;
    line-height: 1;
    margin-bottom: 22px;
    font-weight: ${fontWeights.heavy};
  }

  p {
    font-size: 14px;
    margin-bottom: 24px;
    line-height: 1.6;
  }
`;
