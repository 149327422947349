import styled from 'styled-components';
import { system } from 'styled-system';
import css from '@styled-system/css';
import { Flex, FlexProps } from '../Flex';

// Initial implementation copied from
// https://radius-ds.netlify.app/?path=/docs/components-stack--horizontal

type align = 'start' | 'end' | 'center';

const getAlignment = (value: align) =>
  value === 'start' || value === 'end' ? `flex-${value}` : value;

const stack = system({
  axis: {
    property: 'flexDirection',
    transform: value => (value === 'horizontal' ? 'row' : 'column')
  },
  alignment: {
    property: 'justifyContent',
    transform: getAlignment
  },
  distribution: {
    property: 'alignItems',
    transform: getAlignment
  }
});

type StackProps = {
  axis?: 'horizontal' | 'vertical';
  space?: number | number[];
  alignment?: align;
  distribution?: align;
} & FlexProps;

/**
 * @deprecated Use "Flex" with "gap" prop instead.
 *
 * `Stack` provides a simple API to achieve common "Stacked" layouts, either
 * horizontally or vertically.
 *
 * The main benefit of `Stack` is the `space` prop, which allows us to apply
 * consistent spacing between stacked elements without having to apply spacing to
 * each element, or affecting the the stacked container's surrounding spacing.
 */
export const Stack = styled(Flex)<StackProps>`
  ${stack}

  > * {
    ${props =>
      css({
        [props.axis === 'horizontal' ? 'marginRight' : 'marginBottom']:
          props.space
      })(props)}
  }

  > *:last-child {
    ${props =>
      css({
        [props.axis === 'horizontal' ? 'marginRight' : 'marginBottom']: 0
      })(props)}
  }
`;

Stack.defaultProps = {
  axis: 'horizontal',
  space: 3
};
