import Icon from '@ant-design/icons';
import React from 'react';

const RestrictionSvg = () => (
  <svg
    viewBox="0 0 22 22"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="1em"
    height="1em"
    style={{ display: 'block' }}
  >
    <g
      id="Ant-Design-Template"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        transform="translate(-148.000000, -913.000000)"
        fill="currentColor"
        id="Combined-Shape"
      >
        <path d="M159.994724,913 L160.033,913.039 L167.687195,913.039122 C168.911344,913.039122 169.913294,913.984909 169.994665,915.189309 L170,915.347815 L170,932.691308 C170,933.488246 169.59706,934.190845 168.982043,934.605724 L168.856213,934.684778 C168.562523,934.856519 168.227183,934.965439 167.868435,934.993053 L167.687195,935 L150.312805,935 C149.088656,935 148.086706,934.054213 148.005335,932.849814 L148,932.691308 L148,915.347815 C148,914.125842 148.947356,913.125674 150.154002,913.044448 L150.312805,913.039122 L159.955,913.039 L159.994724,913 Z M149.833,926.881 L149.833333,933.169927 L156.132,933.169 L149.833,926.881 Z M149.833,916.426 L149.833,923.768 L159.251,933.169 L166.606,933.169 L149.833,916.426 Z M158.749,914.869 L151.392,914.869 L168.166,931.613 L168.166,924.269 L158.749,914.869 Z M168.166667,914.869196 L161.866,914.869 L168.166,921.157 L168.166667,914.869196 Z" />
      </g>
    </g>
  </svg>
);

export const RestrictionsIcon = () => <Icon component={RestrictionSvg} />;
