import { Result } from 'antd';
import React, { useEffect } from 'react';
import { useTrackEvent } from '../../hooks/useTrackEvent';
import { TrackingEventNames } from '../../tracking/trackingNames';

export const NotFound: React.FC = () => {
  const track = useTrackEvent();

  useEffect(() => {
    track({
      name: TrackingEventNames.pageNotFound
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Result
      status="404"
      title="404"
      subTitle="Sorry, the page you visited does not exist."
    />
  );
};
