// @ts-strict-ignore
import React, {
  createContext,
  useEffect,
  useContext,
  useCallback
} from 'react';
import { onAuthStateChanged, getAuth } from 'firebase/auth';
import { getDatabase, onValue, ref } from 'firebase/database';

import { Spinner } from '../theme/components/Spinner';
import { FullPageCentered } from '../theme/styles/styles';
import { logout as logoutUtil } from '../utils/auth';
import PaceLocalStorage from '../utils/paceLocalStorage';
import { useClearClientCache } from '../hooks/auth/useClearClientCache';

interface AuthContextType {
  user: null | { email: string; identity: number };
  logout?: () => void;
}

type ReducerState = {
  status: string;
  data: AuthContextType;
  error: null;
};

const AuthContext = createContext<AuthContextType>({ user: null });

const cachedUserData = PaceLocalStorage.getUser();
const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const [{ status, data, error }, setState] = React.useReducer(
    (prev: ReducerState, next: Partial<ReducerState>) => ({ ...prev, ...next }),
    {
      status: cachedUserData ? 'resolved' : 'pending',
      data: { user: cachedUserData },
      error: null
    }
  );

  const handleClearUserCache = useClearClientCache();

  useEffect(() => {
    let unsubscribeUser: () => void | null = null;
    let metadataRef = null;

    /**
     * Docs on control access with custom claims.
     * See Defining roles via Firebase Functions on user creation: Client Side Implementation.
     * https://firebase.google.com/docs/auth/admin/custom-claims
     * Custom claims are set on user creation in cloud functions and set that users refresh time in the database.
     * To propagate them immediately we are listening for changes to the metadata in the database.
     * If there has been a change, then we can force a refresh on their token to get the most recent custom claims.
     */
    const unsubscribe = onAuthStateChanged(getAuth(), async user => {
      try {
        // Remove previous listener.
        if (unsubscribeUser) {
          unsubscribeUser();
        }

        // user is logged in
        if (user) {
          metadataRef = ref(getDatabase(), `metadata/${user.uid}/refreshTime`);
          const callback = async () => {
            // Force refresh to pick up the latest custom claims changes.
            const tokenResult = await user.getIdTokenResult(true);
            if (tokenResult.claims.identity) {
              const userData = {
                identity: tokenResult.claims.identity,
                email: tokenResult.claims.email
              };
              PaceLocalStorage.setUser(userData);
              setState({
                status: 'resolved',
                data: {
                  user: userData
                }
              });
            } else {
              setState({ status: 'pending', data: { user: null } });
            }
          };
          // Subscribe new listener to changes on that node.
          unsubscribeUser = onValue(metadataRef, callback);
        }
        // user is not logged in
        else {
          handleClearUserCache();
          setState({ status: 'resolved', data: { user: null } });
        }
      } catch (error) {
        setState({ status: 'rejected', data: { user: null }, error });
      }
    });
    return () => {
      unsubscribe();
    };
  }, [handleClearUserCache]);

  const logout = useCallback(async () => {
    await logoutUtil();
  }, []);

  const value = React.useMemo(
    () => ({
      user: data?.user,
      logout
    }),
    [data, logout]
  );

  if (status === 'pending') {
    return (
      <FullPageCentered>
        <Spinner />
      </FullPageCentered>
    );
  }
  if (status === 'rejected') throw new Error(error);
  if (status === 'resolved') {
    return (
      <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
    );
  }
};

function useAuth() {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error(`unable to useAuth without AuthProvider`);
  }

  return context;
}

export { useAuth, AuthProvider };
