import { useParams } from 'react-router-dom';
import { RestrictionsTimeframeType, RestrictionsViewType } from '../../types';

export const useRestrictionsNavigationParams = () => {
  const routeParams = useParams<{
    view?: RestrictionsViewType;
    timeframe?: RestrictionsTimeframeType;
  }>();

  return {
    view: routeParams.view,
    timeframe: routeParams.timeframe
  };
};
