import { useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { RestrictionsTimeframeType, RestrictionsViewType } from '../../types';
import paceLocalStorage from '../../../../utils/paceLocalStorage';
import { useRestrictionsNavigationParams } from './useRestrictionsNavigationParams';
import { FeatureFlags, useFlag } from '../../../../hooks/useFlag';

export const useRestrictionsNavigation = (autoRedirect = false) => {
  const history = useHistory();
  const { view: routeParamsView, timeframe: routeParamsTimeframe } =
    useRestrictionsNavigationParams();

  const newRestrictionFramework = useFlag(
    FeatureFlags.NewRestrictionsFramework
  );

  const view =
    routeParamsView ||
    paceLocalStorage.getRestrictionsViewType() ||
    RestrictionsViewType.Effective;

  const timeframe = routeParamsTimeframe || RestrictionsTimeframeType.Current;

  // replace is used only when we load the page and no path is set
  // so we don't get the user stuck in an infinite loop when they press back
  const handleSetNewView = useCallback(
    (newView: RestrictionsViewType, replace = false) => {
      paceLocalStorage.setRestrictionsViewType(newView);

      const method = replace ? history.replace : history.push;

      method({
        pathname: `/restrictions/${newView}`,
        search: history.location.search
      });
    },
    [history]
  );

  const handleSetNewTimeframe = useCallback(
    (newTimeframe: RestrictionsTimeframeType, replace = false) => {
      const method = replace ? history.replace : history.push;

      routeParamsView
        ? method({
            pathname: `/restrictions/${RestrictionsViewType.List}/${newTimeframe}`,
            search: history.location.search
          })
        : method({
            pathname: `/configuration/restrictions/${newTimeframe}`,
            search: history.location.search
          });
    },
    [history.location.search, history.push, history.replace, routeParamsView]
  );

  useEffect(() => {
    if (!autoRedirect) return;

    if (!newRestrictionFramework) {
      if (
        routeParamsView &&
        (view === RestrictionsViewType.Effective ||
          view === RestrictionsViewType.List)
      ) {
        return;
      }

      const location = history.location;
      const params = new URLSearchParams(location.search);

      // if view is as a search param, it will get handled by another redirect
      const hasViewAsParam = params.get('view');
      if (hasViewAsParam) {
        return;
      }

      if (view === RestrictionsViewType.Effective) {
        handleSetNewView(view, true);
        return;
      }
    }

    if (
      routeParamsTimeframe !== RestrictionsTimeframeType.Current &&
      routeParamsTimeframe !== RestrictionsTimeframeType.Past
    ) {
      handleSetNewTimeframe(RestrictionsTimeframeType.Current, true);
    }
  }, [
    autoRedirect,
    routeParamsView,
    view,
    timeframe,
    routeParamsTimeframe,
    handleSetNewTimeframe,
    handleSetNewView,
    history.location,
    newRestrictionFramework
  ]);

  return {
    view,
    timeframe,
    handleSetNewView,
    handleSetNewTimeframe
  };
};
