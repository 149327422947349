import styled from 'styled-components';
import { Box, BoxProps } from '../Box';

type GridProps = BoxProps;

/**
 * `Grid` can be used to implement layouts using CSS grid. It exposes the
 * [Grid](https://styled-system.com/table#grid),
 * [Space](https://styled-system.com/table#space),
 * [Layout](https://styled-system.com/table#layout),
 * [Color](https://styled-system.com/table#color),
 * [Border](https://styled-system.com/table#border),
 * [Position](https://styled-system.com/table#position), and
 * [Flexbox](https://styled-system.com/table#flexbox) properties
 * from [styled-system](https://styled-system.com).
 *
 * Technically, `Grid` is just `Box` with `display: grid`.
 */
export const Grid = styled(Box)<GridProps>({
  display: 'grid'
});
