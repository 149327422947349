import { useMemo } from 'react';
import {
  AccessLevel,
  UserRole,
  usePropertyListQuery
} from '../__generated__/graphql';
import { useCurrentUser } from './useCurrentUser';

const canRead = (access: AccessLevel) =>
  [AccessLevel.Full, AccessLevel.ReadOnly].some(it => access === it);

export const usePermissions = () => {
  const currentUser = useCurrentUser();
  const { data } = usePropertyListQuery();

  return useMemo(
    () => ({
      canWriteProperty: ({ id }: { id?: string }) => {
        if (id) {
          if (!data?.propertyGroup?.properties) return false;

          return data?.propertyGroup?.properties.some(
            property =>
              property.id === id &&
              property.permissions?.accessLevel === AccessLevel.Full
          );
        }

        return false;
      },
      canViewProperty: ({ id }: { id?: string }) => {
        if (id) {
          if (!data?.propertyGroup?.properties) return false;

          return data?.propertyGroup?.properties.some(
            property =>
              property.id === id &&
              canRead(property.permissions?.accessLevel || AccessLevel.None)
          );
        }

        return false;
      },
      isAdmin: () =>
        [UserRole.PaceAdmin, UserRole.PropertyAdmin].some(
          type => type === currentUser?.role
        ),
      isSystemAdmin: () => currentUser?.role === UserRole.PaceAdmin
    }),
    [currentUser, data]
  );
};
