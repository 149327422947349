import { BatchSpanProcessor } from '@opentelemetry/sdk-trace-base';
import { WebTracerProvider } from '@opentelemetry/sdk-trace-web';
import { FetchInstrumentation } from '@opentelemetry/instrumentation-fetch';
import { ZoneContextManager } from '@opentelemetry/context-zone';
import { registerInstrumentations } from '@opentelemetry/instrumentation';
import { Resource } from '@opentelemetry/resources';
import { SemanticResourceAttributes } from '@opentelemetry/semantic-conventions';
import { OTLPTraceExporter } from '@opentelemetry/exporter-trace-otlp-http';
import { DocumentLoadInstrumentation } from '@opentelemetry/instrumentation-document-load';
import { honeycomb } from '../config';
import { transactionId } from '../constants';

const { collectorUrl, username, password } = honeycomb;

if (collectorUrl && username && password) {
  const provider = new WebTracerProvider({
    resource: new Resource({
      [SemanticResourceAttributes.SERVICE_NAME]: 'dashboard'
    })
  });

  const credentials = btoa(`${username}:${password}`);
  const exporter = new OTLPTraceExporter({
    url: honeycomb.collectorUrl,
    headers: {
      Authorization: `Basic ${credentials}`
    }
  });

  provider.addSpanProcessor(new BatchSpanProcessor(exporter));

  provider.register({
    contextManager: new ZoneContextManager()
  });

  registerInstrumentations({
    instrumentations: [
      new DocumentLoadInstrumentation(),
      new FetchInstrumentation({
        // This is essential for ensuring that the traceparent header is added - by default it will only send if same origin
        propagateTraceHeaderCorsUrls: [new RegExp(`${GRAPHQL_URL}.*`)],
        applyCustomAttributesOnSpan: (span, request, result) => {
          span.setAttribute('transaction_id', transactionId);

          try {
            if (request.body && typeof request.body === 'string') {
              const { operationName } = JSON.parse(request.body);
              span.setAttribute('operation_name', operationName);
            }
          } catch (error) {
            //
          }

          if ('headers' in result) {
            const responseContainsErrors = result.headers.get(
              'response-contains-errors'
            );

            if (responseContainsErrors) {
              span.setAttribute('contains_errors', responseContainsErrors);
            }
          }
        }
      })
    ]
  });
}
