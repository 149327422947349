import { CalendarEntryData } from '../../utils/calendarEntriesAggregator';

export enum CalendarViewType {
  Month = 'month',
  Year = 'year',
  List = 'list'
}

export type CalendarEntryEventsByDay = {
  event?: CalendarEntryData[];
  seasonalEvent?: CalendarEntryData[];
  propertyClosure?: CalendarEntryData[];
  notes?: CalendarEntryData[][];
};
