/* eslint-disable */
// @ts-nocheck

if (INTERCOM_APPLICATION_ID) {
  (function () {
    var w = window;
    var ic = w.Intercom;
    if (typeof ic === 'function') {
      ic('reattach_activator');
      ic('update', intercomSettings);
    } else {
      var d = document;
      var i = function () {
        i.c(arguments);
      };
      i.q = [];
      i.c = function (args) {
        i.q.push(args);
      };
      w.Intercom = i;
      function l() {
        var s = d.createElement('script');
        s.type = 'text/javascript';
        s.async = true;
        s.src = 'https://widget.intercom.io/widget/' + INTERCOM_APPLICATION_ID;
        var x = d.getElementsByTagName('script')[0];
        x.parentNode.insertBefore(s, x);
      }
      if (w.attachEvent) {
        w.attachEvent('onload', l);
      } else {
        w.addEventListener('load', l, false);
      }
    }
  })();
}

class Intercom {
  init(data) {
    window.intercomSettings = {
      ...data,
      custom_launcher_selector: '#my_custom_link'
    };
    if (window.Intercom) {
      window.Intercom('boot', { ...data, app_id: INTERCOM_APPLICATION_ID });
    }
  }

  close() {
    if (window.Intercom) {
      window.Intercom('shutdown');
    }
  }
}

const singleton = new Intercom();

export default singleton;
