import React, { HTMLAttributes } from 'react';
import { Box } from '..';
import { ColorYellow70 } from '../../tokens/colors';
import { BoxProps } from '../Box';

const escapeRegex = (value: string) =>
  value.replace(/[-[\]{}()*+?.,\\^$|#]/g, '\\$&');

const highlighter = (
  value: string,
  _highlight: string | undefined | string[]
) => {
  if (_highlight == null || _highlight.length === 0) {
    return [{ chunk: value, highlighted: false }];
  }

  let highlight: string;
  if (Array.isArray(_highlight)) {
    highlight = _highlight.map(escapeRegex).join('|');
  } else {
    highlight = escapeRegex(_highlight);
  }

  const shouldHighlight = highlight.trim() !== '';

  if (!shouldHighlight) {
    return [{ chunk: value, highlighted: false }];
  }

  const matcher = highlight.trim();

  const re = new RegExp(`(${matcher})`, 'gi');
  const chunks = value
    .split(re)
    .map(part => ({ chunk: part, highlighted: re.test(part) }))
    .filter(({ chunk }) => chunk);

  return chunks;
};

type Props = {
  highlight: string | undefined | string[];
  children: string;
} & HTMLAttributes<HTMLElement> &
  BoxProps;

// based on https://github.com/mantinedev/mantine/blob/c7d080c2133b0196e3a8382ec6134838632c8f9a/src/mantine-core/src/Highlight/highlighter/highlighter.ts
export const HighlightMatch = ({ children, highlight, ...props }: Props) => {
  const chunks = highlighter(children, highlight);

  const output = chunks.map(({ chunk, highlighted }, i) =>
    highlighted ? (
      <mark key={i} style={{ background: ColorYellow70, padding: 0 }}>
        {chunk}
      </mark>
    ) : (
      <span key={i}>{chunk}</span>
    )
  );

  return (
    <Box display="inline" {...props}>
      {output}
    </Box>
  );
};
