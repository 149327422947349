import viewports from './viewports';

const { sm } = viewports;

const generateQuery = (viewport: number) => (styles: string) =>
  `
  @media (min-width: ${viewport}px) {
    ${styles}
  }
`;
export const fromSmall = generateQuery(sm);
