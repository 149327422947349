import React from 'react';
import lzString from 'lz-string';
import { Redirect, RouteComponentProps } from 'react-router-dom';
import { AnalyticsQueryParameterName } from '../../constants';

/**
 * Redirects to the insights page. Search is kept to support deep linking to the looker iframe.
 */
export const InsightsRedirect = ({ location }: RouteComponentProps) => (
  <Redirect to={{ pathname: '/insights', search: location.search }} />
);

/**
 * Special redirect for scheduled emails.
 * The link has app domain followed by the looker path, so we need to parse it,
 * compress and include as insights query parameter.
 */
export const InsightsRedirectScheduledEmails = ({
  location
}: RouteComponentProps) => {
  const iFrameCompressedUri = lzString.compressToEncodedURIComponent(
    location.pathname + location.search
  );

  return (
    <Redirect
      to={{
        pathname: '/insights',
        search: `?${AnalyticsQueryParameterName}=${iFrameCompressedUri}`
      }}
    />
  );
};
