import { useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { CalendarViewType } from '../types';
import paceLocalStorage from '../../../utils/paceLocalStorage';
import { useCalendarViewParams } from './useCalendarViewParams';

export const useCalendarView = (autoRedirect = false) => {
  const history = useHistory();
  const { view: routeParamsView } = useCalendarViewParams();

  // replace is used only when we load the page and no path is set
  // so we don't get the user stuck in an infinite loop when they press back
  const handleSetNewView = useCallback(
    (newView: CalendarViewType, replace = false) => {
      paceLocalStorage.setCalendarViewType(newView);

      const method = replace ? history.replace : history.push;

      method({
        pathname: `/calendar/${newView}`,
        search: history.location.search
      });
    },
    [history]
  );

  const view =
    routeParamsView ||
    paceLocalStorage.getCalendarViewType() ||
    CalendarViewType.Year;

  useEffect(() => {
    if (routeParamsView || !autoRedirect) return;
    const location = history.location;
    const params = new URLSearchParams(location.search);
    // if view is as a search param, it will get handled by another redirect
    const hasViewAsParam = params.get('view');
    if (!hasViewAsParam) {
      handleSetNewView(view, true);
    }
  }, [autoRedirect, routeParamsView, view, handleSetNewView, history.location]);

  return {
    view,
    handleSetNewView
  };
};
