import type { Theme } from '.';

/** This method extends theme with properties required for styled-system */
export const extendThemeForStyledSystem = (theme: Theme) => {
  const fontSizes = [
    `${theme.typography.fontSize.xs}px`,
    `${theme.typography.fontSize.sm}px`,
    `${theme.typography.fontSize.default}px`,
    `${theme.typography.fontSize.lg}px`,
    `${theme.typography.fontSize.xl}px`,
    `${theme.typography.fontSize.xxl}px`,
    `${theme.typography.fontSize.xxxl}px`,
    `${theme.typography.fontSize.xxxxl}px`,
    `${theme.typography.fontSize.xxxxxl}px`
  ];

  const space = [0, 4, 8, 16, 32, 64, 128, 256, 512];
  const sizes = [8, 16, 32, 64, 128, 256, 512, 768, 1024, 1536];

  const borders = {
    hairline: `1px solid ${theme.colors.border.default}`,
    warning: `1px solid ${theme.colors.warning.border}`
  };

  const fontWeights = {
    regular: 400,
    medium: 500,
    bold: 700
  };

  const lineHeights = {
    solid: 1,
    title: 1.25,
    copy: 1.5
  };

  /* eslint-disable @typescript-eslint/no-explicit-any */
  (theme as any).fontSizes = fontSizes;
  (theme as any).space = space;
  (theme as any).borders = borders;
  (theme as any).fontWeights = fontWeights;
  (theme as any).lineHeights = lineHeights;
  (theme as any).sizes = sizes;
  /* eslint-enable @typescript-eslint/no-explicit-any */

  return theme;
};
