import React from 'react';
import { intercomApplicationId } from '../../config';

export const DataRetrievalError = ({
  dataDescription
}: {
  dataDescription?: string;
}) => (
  <div>
    <p className="pace-analytics-error-msg">
      {`There was a problem fetching ${
        dataDescription ?? 'your data'
      }. Apologies for any inconvenience
      caused.`}
    </p>
    <p>
      If the problem persists, please contact{' '}
      <a
        id="my_custom_link"
        href={`mailto:${intercomApplicationId}@intercom-mail.com`}
        className="pace-customer-support"
      >
        customer support
      </a>
    </p>
  </div>
);
