import { apiUrl as defaultApiUrl } from '../config';
import { getProp } from '../utils/index';
import { getToken, logout } from '../utils/auth';
import { auth } from '../utils/urls';
import { ApiFactoryOptions } from '../apiSingleton';

const AUTH_URLS = [auth];

export default class ApiClient {
  prefix: string;
  options: ApiFactoryOptions;
  apiUrl: string;

  constructor({ prefix = 'v1/', options = {}, apiUrl = defaultApiUrl } = {}) {
    this.prefix = prefix;
    this.options = options;
    this.apiUrl = apiUrl;
  }

  async get<T>(requestUrl: string) {
    return this.request<T>({
      url: requestUrl,
      method: 'GET'
    });
  }

  async post<T>(requestUrl: string, payload = {}) {
    return this.request<T>({
      url: requestUrl,
      method: 'POST',
      body: payload
    });
  }

  async request<T>({
    url,
    method,
    body
  }: {
    url: string;
    method: string;
    body?: unknown;
  }) {
    const token = await getToken();

    const headers: HeadersInit = {
      Authorization: token ? `JWT ${token}` : '',
      'Cache-Control': 'no-cache',
      'X-Transaction-ID': this.options.transactionId || ''
    };

    if (method.toLowerCase() !== 'get') {
      headers['Content-Type'] = 'application/json';
    }

    const response = await fetch(`${this.apiUrl}${this.prefix}${url}`, {
      method,
      headers,
      body: method !== 'GET' ? JSON.stringify(body) : undefined
    });

    const json = await response.json();

    if (json && json.status_code && json.status_code !== 200) {
      throw json;
    }

    const statusCode = getProp(response, 'status', 401);

    if (statusCode === 401 && !AUTH_URLS.includes(url)) {
      await logout();
    }

    return json as T;
  }
}
