import Base from './Base';
import * as urls from '../utils/urls';
import api from '../apiSingleton';

export type HotelroomSettingsData = {
  hotelroom_id?: string;
  title?: string;
  min_price?: string;
  max_price?: string;
  max_pct_change?: string | null;
  decimal_rounding?: string;
  price_deviation?: string | null;
  group_id?: string | null;
  hotelrooms?: unknown[];
  units?: number | string;
  optimise_prices?: boolean;
};

class Admin extends Base {
  getHotelroomSettingsData = async (propertyId: string) =>
    await api.adminV2.apiClient.get<{ data: HotelroomSettingsData[] }>(
      `${urls.roomsSettings}?property_id=${propertyId}`
    );

  postHotelroomSettingsData = async (
    data: HotelroomSettingsData[],
    propertyId: string,
    performResync: boolean
  ) =>
    await api.adminV2.apiClient.post<{ data: HotelroomSettingsData[] }>(
      `${urls.roomsSettings}?property_id=${propertyId}`,
      {
        data,
        resync_all_prices: performResync
      }
    );
}

export default Admin;
