import React from 'react';
import { Empty } from 'antd';

type Props = {
  description?: React.ReactNode;
};

export const EmptySelectContent = ({ description }: Props) => (
  <Empty
    image={Empty.PRESENTED_IMAGE_SIMPLE}
    style={{ padding: 8 }}
    description={description || 'No results found'}
  />
);
