import { LOCALE, notEmpty, notNil } from '.';

class Formatter {
  currency: string;

  constructor() {
    this.currency = 'GBP';
  }

  setCurrency = (currency: string) => {
    if (notNil(currency) && notEmpty(currency)) {
      this.currency = currency;
    }
  };

  /**
   * @param  {number} value - The number to be formatted
   * @param  {boolean} twoDecimal - Show two decimal places
   * @param  {boolean} noSymbol - Show without a currency symbol
   * @returns {string} The formatted value as a string
   */
  format = (
    value: number | string | undefined | null,
    twoDecimal?: boolean,
    noSymbol = false
  ) => {
    let minimumSignificantDigits;

    if (value === undefined || value === null) return null;
    // TO DO - refactor PerformanceComparison so that it doesn't pass null arguments
    const { currency } = this;
    const number =
      typeof value === 'number' ? value : parseFloat(value.replace(/,/g, ''));

    if (number < 1 && number > 0) {
      minimumSignificantDigits = null;
    } else {
      minimumSignificantDigits = twoDecimal
        ? number.toFixed(2).replace(/,|\.|-/g, '').length
        : number.toString().replace(/,|\.|-/g, '').length;
    }

    let formatter: Intl.NumberFormat;

    if (!noSymbol) {
      if (minimumSignificantDigits) {
        formatter = new Intl.NumberFormat(LOCALE, {
          style: 'currency',
          minimumSignificantDigits,
          currency
        });
      } else {
        formatter = new Intl.NumberFormat(LOCALE, {
          style: 'currency',
          currency
        });
      }
    } else if (twoDecimal) {
      if (minimumSignificantDigits) {
        formatter = new Intl.NumberFormat(LOCALE, {
          style: 'decimal',
          minimumFractionDigits: 2,
          minimumSignificantDigits
        });
      } else {
        formatter = new Intl.NumberFormat(LOCALE, {
          style: 'decimal',
          minimumFractionDigits: 2
        });
      }
    } else {
      formatter = new Intl.NumberFormat(LOCALE, { style: 'decimal' });
    }

    const formatted = formatter.format(Number(number.toFixed(2)));
    const TEMPDecimalFix =
      number > -1 &&
      number < 1 &&
      number !== 0 &&
      formatted.substring(formatted.length - 2, formatted.length) === '00'
        ? formatted.substring(0, formatted.length - 2)
        : formatted;

    return TEMPDecimalFix;
  };

  getSymbol = () => {
    const formatter = new Intl.NumberFormat(LOCALE, {
      style: 'currency',
      currencyDisplay: 'symbol',
      maximumSignificantDigits: 1,
      currency: this.currency
    });
    const justSymbol = formatter.format(0).replace('0', '').trim();
    return justSymbol;
  };

  readonly getCurrency = () => this.currency;
}

const singleton = new Formatter();

export default singleton;
