import styled from 'styled-components';

export const EllipsisOverflow = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const AccessibilityLabel = styled.label`
  display: none;
`;

export const FullPageCentered = styled.div`
  height: 100vh;
  display: flex;
  flex: 1;
`;
