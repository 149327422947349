import startCase from 'lodash/startCase';
import { useEffect } from 'react';
import { useLocation, useRouteMatch } from 'react-router-dom';

export const usePageTitle = () => {
  const { pathname } = useLocation();
  const configPropertyMatch = useRouteMatch<{ page: string }>(
    `/configuration/:page/:propertyName?`
  );
  const revenuePlanningMatch = useRouteMatch<{ page: string }>(
    `/planning/:page`
  );

  const groupsMatch = useRouteMatch<{ page: string }>(
    `/groups/quotation/:quoteId`
  );

  useEffect(() => {
    if (pathname) {
      let pageName = '';
      if (configPropertyMatch) {
        pageName = `Configuration · ${startCase(
          configPropertyMatch?.params?.page
        )}`;
      } else if (revenuePlanningMatch) {
        pageName = `Planning · ${startCase(
          revenuePlanningMatch?.params?.page
        )}`;
      } else if (groupsMatch) {
        pageName = `Quotation · Groups`;
      } else {
        const splitPathList = pathname.split('/');
        pageName = splitPathList[splitPathList.length - 1];
        if (pageName === '') {
          pageName = 'home';
        }
        pageName = startCase(pageName);
      }
      document.title = `${pageName} · FLYR for Hospitality`;
    }
  }, [configPropertyMatch, revenuePlanningMatch, groupsMatch, pathname]);
};
