import { TreeDataNode } from 'antd';

export const allOptionsSelected = (selectedKeys: string[], allKeys: string[]) =>
  selectedKeys &&
  selectedKeys.length > 0 &&
  selectedKeys.length === allKeys.length;

/**
 * Given a list of TreeSelect options, returns all leaf node keys as a flat list
 */
export const getKeys = (options: TreeDataNode[]) => {
  const keys: (string | number)[] = [];
  const traverseTree = (node: TreeDataNode) => {
    if (!node.children || node.children.length === 0) {
      // leaf node
      keys.push(node.key);
    } else {
      // has children, recurse further
      node.children.forEach(child => traverseTree(child));
    }
  };

  options.forEach(node => traverseTree(node));

  return keys.map(v => `${v}`);
};

/**
 * Given a list of selected TreeSelect keys and the
 * tree object used to create that TreeSelect, flattens
 * the list of selection to the full list of selected
 * leaf node
 */
export const getSelectedLeafNodes = (
  selectedKeys: string[],
  options: TreeDataNode[]
): string[] => {
  // use a set for cheaper lookups
  const selectedKeysLookup = new Set(selectedKeys);

  // here we'll collect all keys of selected branches
  const flattenedKeys: string[] = [];

  // recursively walk the tree and add any selected nodes
  // or children of selected nodes to flattenedKeys
  const traverseTree = (node: TreeDataNode, parentIsSelected = false) => {
    const isSelected =
      parentIsSelected || selectedKeysLookup.has(node.key as string);
    // leaf node
    if (!node.children || node.children.length === 0) {
      if (isSelected) {
        flattenedKeys.push(node.key as string);
      }
    }
    // recurse
    else {
      node.children.forEach(child => traverseTree(child, isSelected));
    }
  };

  // run the recursion
  options.forEach(node => traverseTree(node));

  return flattenedKeys;
};
