import React from 'react';
import {
  LoginBackgroundImage,
  FlyrLogo,
  ResponsiveContainer
} from '../../pages/Login/styles';
import { Box, Flex } from '../../theme/components';

interface Props {
  children: React.ReactNode;
}

export const UnauthenticatedLayout = ({ children }: Props) => (
  <Flex
    minHeight="100vh"
    bg="#194D56"
    alignItems="center"
    justifyContent="right"
  >
    <LoginBackgroundImage />
    <ResponsiveContainer>{children}</ResponsiveContainer>
    <Box
      position="absolute"
      bottom={10}
      right={10}
      color="text.secondary"
      fontSize={1}
      zIndex={2}
    >
      {RELEASE ?? 'Development'}
    </Box>
    <Box
      position="absolute"
      top={40}
      left={40}
      color="text.secondary"
      fontSize={1}
      zIndex="3"
    >
      <FlyrLogo />
    </Box>
  </Flex>
);
