import React, { useEffect } from 'react';
import styled from 'styled-components';
import { gql } from '@apollo/client';
import { intercomApplicationId } from '../../../../config';
import Intercom from '../../../../tracking/intercom';
import { useGetIntercomHashQuery } from '../../../../__generated__/graphql';
import { useCurrentUser } from '../../../../hooks/useCurrentUser';

const PlainLink = styled.a`
  background: transparent;
  border: none;
  color: unset;
`;

gql`
  query GetIntercomHash {
    intercomIdentityHash
  }
`;

interface Props {
  children: React.ReactNode;
}

export const IntercomButton = ({ children }: Props) => {
  const currentUser = useCurrentUser();
  const { data } = useGetIntercomHashQuery();

  useEffect(() => {
    if (currentUser && data?.intercomIdentityHash) {
      Intercom.init({
        user_hash: data.intercomIdentityHash,
        user_id: currentUser.id,
        email: currentUser.email,
        property_group: {
          name: currentUser.propertyGroupName,
          id: currentUser.propertyGroupId
        },
        user_type: currentUser.role
      });
    }
  }, [currentUser, data]);

  return (
    <PlainLink
      id="my_custom_link"
      href={`mailto:${intercomApplicationId}@intercom-mail.com`}
    >
      {children}
    </PlainLink>
  );
};
