import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';

export const useUpdateIntercom = () => {
  const location = useLocation();

  useEffect(() => {
    if (window.Intercom) {
      window.Intercom('update', {
        last_request_at: Math.round(new Date().getTime() / 1000)
      });
    }
  }, [location]);
};
