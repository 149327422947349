import { gql } from '@apollo/client';
import { atom, useAtom } from 'jotai';
import moment from 'moment-timezone';
import { useEffect, useMemo } from 'react';
import {
  AccessLevel,
  GetEnhancedPropertyQuery,
  PropertyListQueryResult,
  useGetEnhancedPropertyQuery,
  usePropertyListQuery
} from '../__generated__/graphql';
import paceLocalStorage from '../utils/paceLocalStorage';
import formatter from '../utils/formatter';
import { logout } from '../utils/auth';

const currentPropertyId = paceLocalStorage.getCurrentPropertyId();

export const currentPropertyIdAtom = atom<string>(currentPropertyId || '');

gql`
  query PropertyList {
    propertyGroup {
      id
      properties {
        id
        name
        pmsTypeId
        currency
        timezone
        permissions {
          id
          accessLevel
          canEdit
        }
      }
    }
  }
`;

export interface PropertyWithAccessLevel {
  id: string;
  name: string;
  accessLevel: AccessLevel;
  currency: string;
  pmsTypeId?: string;
  timezone: string;
}

export const useCurrentProperty = (): PropertyWithAccessLevel => {
  const [propertyId, setPropertyId] = useAtom(currentPropertyIdAtom);

  const { data: properties } = usePropertyListQuery({
    onCompleted: async data => {
      const matchingProperty = data.propertyGroup?.properties.find(
        p => p.id === propertyId
      );
      if (!matchingProperty) {
        setPropertyId(data.propertyGroup.properties[0].id);
      }

      const properties = data?.propertyGroup.properties;
      if (properties && !properties.length) {
        await logout();
        throw new Error('User does not have any properties');
      }
    }
  });

  const formattedProperty = useMemo(
    () =>
      getFormattedProperty({
        propertyId,
        propertyListProperties: properties
      }),
    [propertyId, properties]
  );

  const currency = formattedProperty.currency;
  useEffect(() => {
    if (currency) {
      formatter.setCurrency(currency);
    }
  }, [currency]);

  return formattedProperty;
};

export const getFormattedProperty = ({
  propertyId,
  propertyListProperties
}: {
  propertyId: string;
  propertyListProperties?: PropertyListQueryResult['data'];
}): PropertyWithAccessLevel => {
  const foundProperty = propertyListProperties?.propertyGroup.properties.find(
    property => property.id === propertyId
  );

  if (!foundProperty) {
    return {
      id: propertyId,
      accessLevel: AccessLevel.None,
      name: '',
      currency: '',
      timezone: moment.tz.guess()
    };
  }

  return {
    currency: foundProperty.currency,
    id: foundProperty.id,
    name: foundProperty.name,
    pmsTypeId: foundProperty.pmsTypeId,
    accessLevel: foundProperty.permissions?.accessLevel || AccessLevel.ReadOnly,
    timezone: foundProperty.timezone
  };
};

export type RatePlan = NonNullable<
  NonNullable<GetEnhancedPropertyQuery['property']>['ratePlans']
>[0];

// enhanced property
gql`
  query GetEnhancedProperty($propertyId: ID!) {
    property(id: $propertyId) {
      id
      automationOn
      ratePlans {
        id
        name
        restrictionClassification
        status
        inventories {
          alternativeId
          inventoryGroup {
            id
          }
        }
        segment {
          name
        }
      }
    }
  }
`;

/**
 * Enhanced with more expensive data fetching such as "automationOn", "ratePlans"
 */
export const useCurrentEnhancedProperty = ({
  skip
}: { skip?: boolean } = {}) => {
  const currentProperty = useCurrentProperty();

  const { data } = useGetEnhancedPropertyQuery({
    variables: {
      propertyId: currentProperty.id
    },
    skip: !currentProperty.id || skip
  });

  return {
    ...currentProperty,
    automationOn: data?.property?.automationOn,
    ratePlans: data?.property?.ratePlans || []
  };
};
