import React, { useCallback } from 'react';
import {
  Redirect,
  Route,
  RouteComponentProps,
  RouteProps
} from 'react-router-dom';
import { getPropertyIdFromLocationSearch } from '../../utils/property';
import { NotFound } from './NotFound';
import { useCurrentProperty } from '../../hooks/useCurrentProperty';
import { PropertyIDQueryParameterName } from '../../constants';

type Props = {
  isAllowed: boolean | ((routeProps: { property: string }) => boolean);
};

/**
 * ProtectedRoutes are Routes that require an isAllowed predicate or predicate function. The result of isAllowed will determine
 * whether we display the route or throw a 404.
 */
export const ProtectedRoute: React.FC<RouteProps & Props> = ({
  render,
  component,
  isAllowed,
  ...props
}) => {
  const currentProperty = useCurrentProperty();
  const propertyId = getPropertyIdFromLocationSearch(
    props.location?.search || ''
  );

  const allowed =
    typeof isAllowed === 'function'
      ? isAllowed({
          property: propertyId || ''
        })
      : isAllowed;

  const renderFn = useCallback(
    (routeProps: RouteComponentProps) => {
      if (!allowed) {
        return <NotFound />;
      }

      if (render) {
        return render(routeProps);
      }

      if (component) {
        return React.createElement(component, routeProps);
      }
    },
    [component, render, allowed]
  );

  // this happens when there's no property in the url, for example visiting "/restrictions"
  // redirects to the last property
  if (!allowed && !propertyId && currentProperty.id) {
    const newSearch = new URLSearchParams(props.location?.search || '');
    newSearch.append(PropertyIDQueryParameterName, currentProperty.id);

    return (
      <Redirect
        push={false}
        to={{
          pathname: props.location?.pathname || '',
          search: newSearch.toString()
        }}
      />
    );
  }

  return <Route {...props} render={renderFn} />;
};
