import { gql } from '@apollo/client';
import Base from './Base';
import { aggregatePriceManagementData } from '../utils/transformResponse';
import { client } from '../graphqlClient';
import { InventoryGroup, PriceManagementRow } from '../__generated__/graphql';

const GET_INVENTORY_GROUPS_QUERY = gql`
  query PriceManagementInventoryGroups($propertyId: ID!) {
    inventoryGroups(propertyId: $propertyId) {
      _id
      defaultInventoryId
      name
      children {
        _id
        name
        deviationType
        deviationAmount
      }
    }
  }
`;

const GET_PRICING_DATA_QUERY = gql`
  query PriceManagementData(
    $startDate: Date!
    $endDate: Date!
    $properties: [ID!]
  ) {
    priceManagement(
      startDate: $startDate
      endDate: $endDate
      properties: $properties
    ) {
      id
      inventoryId
      values {
        __typename
        ... on PriceManagementPropertyCell {
          id
          date
          occupancy
          leftToSell
        }
        ... on PriceManagementPriceCell {
          id
          date
          leftToSell
        }
      }
    }
  }
`;

export default class PriceManagementAPI extends Base {
  getTableData = async (
    propertyId: string,
    dates: { startDate: string; endDate: string }
  ) => {
    const { startDate = '', endDate = '' } = dates;

    const response = await Promise.all([
      client
        .query({
          fetchPolicy: 'network-only',
          query: GET_INVENTORY_GROUPS_QUERY,
          variables: {
            propertyId,
            includeVirtual: true
          }
        })
        .then(({ data }) => data.inventoryGroups as InventoryGroup[]),
      client
        .query({
          fetchPolicy: 'network-only',
          query: GET_PRICING_DATA_QUERY,
          variables: {
            properties: [propertyId],
            startDate,
            endDate
          }
        })
        .then(({ data }) => data.priceManagement as PriceManagementRow[])
    ]);

    return {
      ...aggregatePriceManagementData(dates, propertyId, response),
      propertyId
    };
  };
}
