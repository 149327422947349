import React, { Component } from 'react';
import * as Sentry from '@sentry/browser';
import { Button } from 'antd';
import { generateErrorMsg } from '../../utils/error';
import { StyledErrorBoundary } from './ErrorBoundary.styles';

interface Props {
  children: React.ReactNode;
}

export class ErrorBoundary extends Component<Props, { hasError: boolean }> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: Error) {
    const newError = new Error(error as unknown as string);
    // eslint-disable-next-line no-console
    console.log(error);
    Sentry.captureMessage(
      generateErrorMsg({
        method: 'ErrorBoundary',
        msg: error
      })
    );
    Sentry.captureException(newError);
  }

  render() {
    return this.state.hasError ? (
      <StyledErrorBoundary>
        <div>
          <h1>Oops.</h1>
          <h2>Looks like an error has occurred.</h2>
          <p>
            Please get in touch with our Customer Success team if this problem
            persists.
          </p>
          <Button ghost onClick={() => window.location.reload()}>
            Refresh Page
          </Button>
        </div>
      </StyledErrorBoundary>
    ) : (
      this.props.children
    );
  }
}
