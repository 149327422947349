import { useEffect } from 'react';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import { useCurrentProperty } from '../../hooks/useCurrentProperty';
import { useCurrentUser } from '../../hooks/useCurrentUser';

enum UserType {
  PaceAdmin = 'pace_admin',
  Admin = 'property_admin',
  User = 'property_user'
}

export function useSetupFeatureFlag() {
  const currentUser = useCurrentUser();
  const propertyCurrentlySet = useCurrentProperty();
  const launchDarklyClient = useLDClient();

  useEffect(() => {
    // set launch darkly feature flag user
    if (launchDarklyClient && currentUser && propertyCurrentlySet) {
      launchDarklyClient.identify({
        key: String(currentUser.id),
        email: currentUser.email,
        custom: {
          propertyGroupName: currentUser.propertyGroupName,
          userType: UserType[currentUser.role as keyof typeof UserType],
          pmsType: propertyCurrentlySet?.pmsTypeId || ''
        }
      });
    }
  }, [launchDarklyClient, currentUser, propertyCurrentlySet]);
}
