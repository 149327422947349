export enum RestrictionsViewType {
  Effective = 'effective', // Calendar view
  List = 'list'
}

export enum RestrictionsTimeframeType {
  Current = 'current',
  Past = 'past'
}

export enum GroupByType {
  RatePlan = 'ratePlan',
  Inventory = 'inventory'
}
