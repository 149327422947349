// @ts-strict-ignore
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { createHttpLink } from '@apollo/client/link/http/createHttpLink';
import { RetryLink } from '@apollo/client/link/retry';
import { ServerParseError } from '@apollo/client';
import { captureMessage } from '@sentry/browser';
import { getToken } from './utils/auth';

const GenericErrorMessagesForStatusCode = {
  413: 'Sorry, the change you tried to apply was too large. Please reduce the scope of what you are modifying and try again.',
  408: `Sorry, looks like we could not fetch what you needed. Please try again later.`,
  418: `Mind getting some biscuits?`,
  429: `Sorry, looks like we could not fetch what you needed. Please try again later.`,
  500: `Sorry, our servers are having some issues.`,
  502: `Sorry, our servers are having some issues.`,
  503: `Sorry, our servers are having some issues. We should be back up soon.`,
  504: `Sorry, looks like we could not fetch what you needed. Please try again later.`
};

export const authLink = setContext(async () => {
  // get the authentication token
  const token = await getToken();
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      authorization: token ? `JWT ${token}` : ''
    }
  };
});

export const errorLink = onError(
  ({ graphQLErrors, networkError, operation, forward }) => {
    if (graphQLErrors) {
      for (const err of graphQLErrors) {
        if (err.extensions.exception?.code === 'ECONNRESET') {
          // Retry the request
          return forward(operation);
        }
      }
    }

    if (networkError) {
      captureMessage(`[Network error]: ${networkError}`, {
        level: 'error'
      });

      const statusCode = (networkError as ServerParseError).statusCode;
      if (
        Object.keys(GenericErrorMessagesForStatusCode).includes(
          statusCode?.toString()
        )
      ) {
        import('antd/es/message').then(message =>
          message.default.error(
            GenericErrorMessagesForStatusCode[
              statusCode as keyof typeof GenericErrorMessagesForStatusCode
            ]
          )
        );
      }
    }
  }
);

export const httpLink = createHttpLink({
  uri: GRAPHQL_URL,
  fetch: (uri, options) => {
    if (typeof options.body !== 'string') {
      return fetch(uri, options);
    }

    try {
      const { operationName, variables } = JSON.parse(options.body);
      const opName = [operationName, variables?.id].filter(Boolean).join('-');
      return fetch(`${uri}?opname=${encodeURIComponent(opName)}`, options);
    } catch (e) {
      return fetch(uri, options);
    }
  }
});

export const retryLink = new RetryLink({
  delay: {
    initial: 100
  },
  attempts: {
    max: 3
  }
});

export const sentryTransactionIdLink = (transactionId: string) =>
  setContext((req, prevContext) => ({
    ...prevContext,
    headers: {
      ...prevContext.headers,
      'X-Transaction-ID': transactionId
    }
  }));
