import React from 'react';
import slug from 'slug';
import { Redirect, useLocation, useParams } from 'react-router-dom';
import { useCurrentProperty } from '../../hooks/useCurrentProperty';
import { usePropertyListQuery } from '../../__generated__/graphql';

interface PropertyRedirectRouteProps {
  newPath: string;
  originalPath: string;
}

export const PropertyRedirectRoute = ({
  newPath,
  originalPath
}: PropertyRedirectRouteProps) => {
  const { propertyName } = useParams<{ propertyName: string }>();
  const currentProperty = useCurrentProperty();
  const location = useLocation();

  const { data: properties } = usePropertyListQuery();

  const propertyBySlug = properties?.propertyGroup.properties?.find(
    property => slug(property.name).toLowerCase() === propertyName
  );
  const propertyId = propertyBySlug?.id || currentProperty.id;

  const searchParams = new URLSearchParams(location.search);
  searchParams.append('property', propertyId);

  return (
    <Redirect
      push={false}
      from={originalPath}
      to={{
        pathname: newPath,
        search: searchParams.toString()
      }}
    />
  );
};
