// @ts-strict-ignore
import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import {
  getAuth,
  GoogleAuthProvider,
  OAuthProvider,
  SAMLAuthProvider,
  signInWithPopup
} from 'firebase/auth';
import { Alert, Button } from 'antd';
import { BottomLink } from './styles';
import { Stack } from '../../theme/components';
import { useSSOSettings } from './hooks/useSSOSettings';
import { DataRetrievalError } from '../../components/DataRetrievalError';
import { UnauthenticatedLayout } from '../../components/Layout/UnauthenticatedLayout';
import PaceLocalStorage from '../../utils/paceLocalStorage';
import { SsoSettingsQuery } from '../../__generated__/graphql';

type AuthProvider = SsoSettingsQuery['ssoSettings']['providers'][number];

const getProvider = (config: AuthProvider) => {
  switch (config.__typename) {
    case 'GoogleProvider':
      return new GoogleAuthProvider();
    case 'OAuthProvider':
      return new OAuthProvider(config.id);
    case 'SAMLProvider':
      return new SAMLAuthProvider(config.id);
    default:
      throw new Error(
        `Unknown provider type: ${
          (config as { __typename: string }).__typename
        }`
      );
  }
};

export const SSO = () => {
  const { tenant } = useParams<{ tenant: string }>();
  const history = useHistory();

  const { settings, loading, error } = useSSOSettings(tenant);

  useEffect(() => {
    if (settings && settings.tenantId) {
      PaceLocalStorage.setTenant(tenant);
      getAuth().tenantId = settings.tenantId;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [settings]);

  const triggerAuth = (provider: AuthProvider) => {
    const providerInstance = getProvider(provider);
    const auth = getAuth();
    signInWithPopup(auth, providerInstance);
  };

  const getContent = () => {
    if (error) return <DataRetrievalError dataDescription="SSO Config" />;
    if (loading) return <div>Loading...</div>;
    return settings?.providers && settings.providers.length > 0 ? (
      settings.providers.map(provider => (
        <Button
          key={provider.id}
          type="primary"
          onClick={() => triggerAuth(provider)}
        >
          Login with {provider.label}
        </Button>
      ))
    ) : (
      <Alert
        type="warning"
        showIcon
        message="Single Sign On is not configured"
      />
    );
  };

  return (
    <UnauthenticatedLayout>
      <Stack axis="vertical" space={3}>
        {getContent()}

        <BottomLink
          onClick={() => {
            // see: https://cloud.google.com/identity-platform/docs/multi-tenancy-authentication#sign_in_with_tenants
            getAuth().tenantId = null;
            PaceLocalStorage.setTenant('');
            history.replace('/login');
          }}
        >
          Back to Email Login
        </BottomLink>
      </Stack>
    </UnauthenticatedLayout>
  );
};
