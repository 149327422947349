import React from 'react';
import { Dropdown, MenuProps } from 'antd';
import { useAuth } from '../../../../contexts/AuthContext';

interface Props {
  children: React.ReactNode;
  open: boolean;
  onOpenChange?: (open: boolean) => void;
}

export const UserDropdown = ({ children, open, onOpenChange }: Props) => {
  const { logout, user } = useAuth();

  const items: MenuProps['items'] = [
    {
      key: 0,
      label: user?.email,
      disabled: true
    },
    {
      type: 'divider'
    },
    {
      key: 1,
      label: 'Logout',
      onClick: logout
    }
  ];

  return (
    <Dropdown
      open={open}
      menu={{ items }}
      onOpenChange={onOpenChange}
      trigger={['click']}
    >
      {children}
    </Dropdown>
  );
};
