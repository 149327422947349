// @ts-strict-ignore
import moment, { Moment } from 'moment';
import get from 'lodash/get';
import isNil from 'lodash/isNil';
import isEmpty from 'lodash/isEmpty';
import { forDates } from '../constants';
import formatter from './formatter';
import {
  DeviationType,
  TaxCodeType,
  TaxSetting
} from '../__generated__/graphql';

export const LOCALE =
  navigator.language ||
  (navigator as { userLanguage?: string }).userLanguage ||
  'en-GB';

export const noop = () => {};

export const notEmpty = (val: unknown) => !isEmpty(val);

export const notNil = (val: unknown) => !isNil(val);

export const getProp = <T>(
  object = {},
  path = '',
  defaultValue: T | null = null
) => {
  const val = get(object, path, defaultValue);
  return (notNil(val) ? val : defaultValue) as T;
};

export const formatDeviation = (value: number | string, type: DeviationType) =>
  type === 'absolute'
    ? formatter.format(value, true)
    : `${Number(value).toFixed(0)}%`;

export const applyDeviation = (
  price: string,
  type: DeviationType,
  deviation: string
) =>
  type === 'percentage'
    ? (parseFloat(price) * (1 + parseFloat(deviation) / 100)).toFixed(2)
    : (parseFloat(price) + parseFloat(deviation)).toFixed(2);

export const dateRange = (
  start: Moment,
  end: Moment,
  dateFormat = forDates.useLocalizedFullDate
) => {
  const days: string[] = [];
  let day = start;

  while (day <= end) {
    days.push(day.format(dateFormat));
    day = day.clone().add(1, 'd');
  }

  return days;
};

export const normalizeDate = (dateString: string) =>
  moment
    .utc(dateString, [forDates.useISOFormat, forDates.useLocalizedFullDate])
    .format(forDates.useISOFormat);

export const downloadReportCSVFile = (
  data: string[],
  name = 'manager_report'
) => {
  let csvContent = 'data:text/csv;charset=utf-8,';
  data.forEach(rowData => {
    csvContent += `${rowData}\r\n`;
  });

  const encodedUri = encodeURI(csvContent);
  const link = document.createElement('a');
  link.setAttribute('href', encodedUri);
  link.setAttribute('download', `${name}.csv`);
  document.body.appendChild(link);

  link.click();
};

export const getNetRate = (price: number, taxes: TaxSetting) => {
  if (isEmpty(taxes)) return price;
  if (taxes.type === TaxCodeType.Percentage)
    return price / (1 + taxes.value / 100);
  if (taxes.type === TaxCodeType.Absolute) return price - taxes.value;
};

export const getRGBA = (colorString: string, opacity: number) => {
  const r = parseInt(colorString.slice(1, 3), 16);
  const g = parseInt(colorString.slice(3, 5), 16);
  const b = parseInt(colorString.slice(5, 7), 16);
  return `rgba(${r},${g},${b},${opacity})`;
};

export const formatGraphqlErrorMessage = (graphQLErrors?: readonly Error[]) => {
  if (graphQLErrors) {
    return graphQLErrors.reduce(
      (message, error, index) =>
        index ? `${message} ${error.message}` : error.message,
      ''
    );
  }

  return 'Error retrieving data';
};

/** Similar to lodash.omit but recursively "deep" */
export const omitDeep = <T extends Record<string, unknown> | unknown[]>(
  value: T,
  keys: string[]
): T => {
  keys.forEach(key => {
    value = omitKeyDeep(value, key);
  });
  return value;
};

const omitKeyDeep = <T, K extends string & keyof T>(
  value: T,
  key: string
): T => {
  if (Array.isArray(value)) {
    return value.map(i => omitKeyDeep(i, key)) as T;
  }

  if (typeof value === 'object' && value !== null) {
    return Object.keys(value).reduce((newObject, k: K) => {
      // eslint-disable-next-line eqeqeq
      if (k == key) return newObject;
      return { [k]: omitKeyDeep(value[k], key), ...newObject };
    }, {}) as T;
  }

  return value;
};

export const formatPercentage = (
  value: number,
  decimalPlaces = 0,
  opts?: Intl.NumberFormatOptions
) =>
  new Intl.NumberFormat(LOCALE, {
    style: 'percent',
    minimumFractionDigits: decimalPlaces,
    maximumFractionDigits: decimalPlaces,
    ...opts
  }).format(value);

export const formatCurrency = (
  currency: string,
  value: number | undefined,
  opts?: Intl.NumberFormatOptions
) => {
  const digitOpts =
    value && value > 0 && value < 1
      ? { minimumFractionDigits: 2, maximumFractionDigits: 2 }
      : { minimumFractionDigits: 0, maximumFractionDigits: 0 };

  if (value === undefined) {
    const parts = new Intl.NumberFormat(LOCALE, {
      style: 'currency',
      currency,
      ...digitOpts,
      ...opts
    }).formatToParts(0);

    return parts
      .map(part => {
        if (
          part.type === 'integer' ||
          part.type === 'decimal' ||
          part.type === 'fraction'
        ) {
          return '—';
        }

        return part.value;
      })
      .join(' ');
  }

  return new Intl.NumberFormat(LOCALE, {
    style: 'currency',
    currency,
    ...digitOpts,
    ...opts
  }).format(value);
};

export const formatCompactCurrency = (currency: string, value: number) =>
  formatCurrency(currency, value, {
    notation: 'compact',
    compactDisplay: 'short',
    minimumFractionDigits: undefined,
    maximumFractionDigits: undefined
  });

export const formatUnit = (value = 0, opts?: Intl.NumberFormatOptions) =>
  new Intl.NumberFormat(LOCALE, opts).format(value);

export const filterDefined = <T>(array: (T | null | undefined)[]): T[] => {
  const filteredArray = array.filter(a => a != null);
  if (filteredArray.length === array.length) {
    return array as T[];
  }

  return filteredArray as T[];
};
