import { useLocation } from 'react-router-dom';
import { useEffect, useRef } from 'react';
import debounce from 'lodash/debounce';
import { useTrackEvent } from '../../hooks/useTrackEvent';
import { TrackingEventNames } from '../../tracking/trackingNames';

export const useTrackPageViews = () => {
  const location = useLocation();
  const previousPath = useRef('');
  // debouncing track calls to avoid tracking multiple events when a redirect occurs or user is quickly switching between pages
  const debouncedTrack = useRef(debounce(useTrackEvent(), 500)).current;

  useEffect(() => {
    const payload = {
      name: TrackingEventNames.pageView,
      pageName: document.title.replace('· FLYR for Hospitality', '').trim(),
      url: window.location.href,
      host: window.location.hostname,
      path: location.pathname
    };

    const onlyQueryParamsChanged = previousPath.current === payload.path;
    if (!onlyQueryParamsChanged) {
      debouncedTrack(payload);
      previousPath.current = payload.path;
    }
  }, [location, debouncedTrack]);
};
