import styled from 'styled-components';
import {
  border,
  color,
  compose,
  flexbox,
  fontSize,
  fontWeight,
  grid,
  layout,
  lineHeight,
  position,
  shadow,
  space,
  textAlign,
  BorderProps,
  ColorProps,
  FlexboxProps,
  FontSizeProps,
  FontWeightProps,
  GridProps,
  LayoutProps,
  LineHeightProps,
  PositionProps,
  ShadowProps,
  SpaceProps,
  TextAlignProps
} from 'styled-system';

export type BoxProps = React.PropsWithChildren<
  SpaceProps &
    ColorProps &
    LayoutProps &
    FlexboxProps &
    BorderProps &
    ShadowProps &
    PositionProps &
    GridProps &
    FontSizeProps &
    FontWeightProps &
    LineHeightProps &
    TextAlignProps
>;

/**
 * `Box` is the primitive component for composing layouts. It exposes the
 * [Space](https://styled-system.com/table#space),
 * [Layout](https://styled-system.com/table#layout),
 * [Color](https://styled-system.com/table#color),
 * [Border](https://styled-system.com/table#border),
 * [Shadow](https://styled-system.com/table#shadow),
 * [Position](https://styled-system.com/table#position),
 * [Grid](https://styled-system.com/table#grid), and
 * [Flexbox](https://styled-system.com/table#flexbox) properties
 * from [styled-system](https://styled-system.com).
 *
 * The purpose of `Box` (and its higher-level implementations, `Flex`, `Stack` and `Grid`) are
 * to reduce the amount of bespoke CSS we need to write for screen, and to ensure consistency
 * via [Theming](https://styled-system.com/#theming)
 *
 * - `Box` props can use scalar values defined in our theme, e.g.
 *  - `<Box p={3} bg="primary"/>`.
 *  - If necessary, you can provide raw CSS values for
 * one-off customization, but these should be used sparingly.
 * - `Box` properties can be provides as [Responsive Styles](https://styled-system.com/responsive-styles) using
 * the Array syntax, e.g.
 *  - `<Box mb={[1, 3]} />`
 * - `Box` renders a `div` by default, but you can also provide a component
 * type using the `as` prop, e.g.
 *  - `<Box as={Component}>`
 *  - `<Box as="nav" />`.
 *
 * **Note:** While `Box` implements [Flexbox](https://styled-system.com/table#flexbox) properties,
 *   it does **not** set `display: flex`. For that, you should use the `Flex` component.
 */
export const Box = styled.div<BoxProps>(
  {
    boxSizing: 'border-box',
    minWidth: 0,
    fontFamily: 'inherit'
  },
  compose(
    border,
    color,
    flexbox,
    fontSize,
    fontWeight,
    grid,
    layout,
    lineHeight,
    position,
    shadow,
    space,
    textAlign
  )
);
