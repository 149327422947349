import styled, { CSSObject } from 'styled-components';
import { Box, BoxProps } from '../Box';

const FLEX_GAP_MULTIPLYER = 8;

export type FlexProps = BoxProps & {
  /**
   * The gap between flex items, it gets multiplied by 8 and sets `gap` CSS property.
   */
  gap?: number;
};

/**
 * The `Flex` component can be used to implement flexbox layouts.
 *
 * - Combined with `Box`, `Flex` can be used to implement pretty much any layout.
 * - However, before using `Flex`, you should evaluate whether a `Stack` would do the job.
 * - In multi-column layouts, a `Grid` might be a simpler solution.
 *
 * Similar to `Box`, `Flex` exposes the
 * [Space](https://styled-system.com/table#space),
 * [Layout](https://styled-system.com/table#layout),
 * [Color](https://styled-system.com/table#color),
 * [Border](https://styled-system.com/table#border),
 * [Position](https://styled-system.com/table#position), and
 * [Flexbox](https://styled-system.com/table#flexbox) properties
 * from [styled-system](https://styled-system.com).
 */
export const Flex = styled(Box)<FlexProps>(
  ({ gap = 0 }): CSSObject => ({
    display: 'flex',
    gap: calculateGap(gap)
  })
);

const calculateGap = (gap: number): string => `${gap * FLEX_GAP_MULTIPLYER}px`;
