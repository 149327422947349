// @ts-strict-ignore
import React, { useMemo, useState } from 'react';
import { Checkbox, Select } from 'antd';
import styled from 'styled-components';
import isArray from 'lodash/isArray';
import { DownOutlined, SearchOutlined } from '@ant-design/icons';
import { ColorGrey70 } from '../../tokens/colors';
import { EmptySelectContent } from '..';

const ItemCheckbox = styled(Checkbox)`
  margin-right: 12px;

  .ant-checkbox:hover .ant-checkbox-inner,
  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: ${ColorGrey70};
  }
`;

const StyledSelect = styled(Select)`
  & .ant-select-selection-item {
    border: none;
    background-color: transparent;
    height: auto;
    margin: 0;
  }
`;

type MultiSelectProps = React.ComponentProps<typeof Select> & {
  /**
   * [singular, plural]
   */
  selectedLabel: [string, string];
  emptyStateDescription?: React.ReactNode;
};

const SELECT_ALL_VALUE = 'select-all';

export const MultiSelect = (props: MultiSelectProps) => {
  const [isOpened, setIsOpened] = useState<boolean>(props.defaultOpen || false);
  const { options, selectedLabel, emptyStateDescription, ...passThroughProps } =
    props;
  const valueFromProps = useMemo(
    () => (isArray(props.value) ? props.value : []),
    [props.value]
  );

  const extendedOptions = [
    ...(!props.searchValue && options.length > 1
      ? [
          {
            value: SELECT_ALL_VALUE,
            label: `All ${selectedLabel[1]}`
          }
        ]
      : []),
    ...options
  ];

  const areAllSelected = useMemo(
    () =>
      options.length > 0 &&
      options
        .filter(o => !o.disabled)
        .every(o => valueFromProps.includes(o.value)),
    [options, valueFromProps]
  );

  const value = [
    ...(areAllSelected ? [SELECT_ALL_VALUE] : []),
    ...valueFromProps
  ];

  const isAnySelected = !areAllSelected && valueFromProps.length > 0;

  const getSelectedDisplay = (value: string[]) =>
    areAllSelected
      ? `All ${selectedLabel[1]} (${value.length})`
      : `${value.length} ${selectedLabel[Number(value.length > 1)]}`;

  const searchContents = !isOpened && getSelectedDisplay(valueFromProps);

  return (
    <StyledSelect
      {...passThroughProps}
      popupClassName="pace-multi-select-popup"
      notFoundContent={
        <EmptySelectContent description={emptyStateDescription} />
      }
      maxTagCount={0}
      maxTagPlaceholder={searchContents}
      suffixIcon={isOpened ? <SearchOutlined /> : <DownOutlined />}
      menuItemSelectedIcon={false}
      mode="multiple"
      value={value}
      onInputKeyDown={e => {
        // disables removing selection with backspace
        if (!props.searchValue && e.key === 'Backspace') {
          e.stopPropagation();
        }
      }}
      onDeselect={option => {
        if (option === SELECT_ALL_VALUE) {
          const filtered = options.filter(o => o.alwaysChecked);
          props.onChange(
            filtered.map(o => o.value),
            options
          );
        }
      }}
      onSelect={option => {
        if (option === SELECT_ALL_VALUE) {
          const filtered = options.filter(o => !o.disabled || o.alwaysChecked);
          props.onChange(
            filtered.map(o => o.value),
            options
          );
        }
      }}
      onChange={(value, options) => {
        props.onChange(
          isArray(value) ? value.filter(v => v !== SELECT_ALL_VALUE) : value,
          options
        );
      }}
      filterOption={(input, option) => {
        if (input && option.value === SELECT_ALL_VALUE) {
          return false;
        }

        return option.label.toLowerCase().includes(input.toLowerCase());
      }}
      onDropdownVisibleChange={setIsOpened}
    >
      {extendedOptions.map(option => (
        <Select.Option
          // eslint-disable-next-line jsx-a11y/aria-role
          role="item"
          key={option.value}
          label={option.label}
          disabled={option.disabled}
        >
          <ItemCheckbox
            checked={value.includes(option.value) || option.alwaysChecked}
            indeterminate={option.value === SELECT_ALL_VALUE && isAnySelected}
            disabled={option.disabled}
          />
          {option.label}
        </Select.Option>
      ))}
    </StyledSelect>
  );
};
