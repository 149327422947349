import React from 'react';
import * as Sentry from '@sentry/browser';

// When we release the dashboard, the old JS chunks are purged from the CDN cache, and navigating to a page that uses them will cause an error
// This should catch the error and automatically reload the page
export function lazyRetry<T>(
  componentImport: () => Promise<{
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    default: (props: T) => any;
  }>,
  name: string
) {
  return React.lazy<React.ComponentType<T>>(
    () =>
      new Promise((resolve, reject) => {
        const storageName = `loaded-${name}`;
        const hasRefreshed = JSON.parse(
          window.sessionStorage.getItem(storageName) || 'false'
        );

        componentImport()
          .then(component => {
            window.sessionStorage.setItem(storageName, 'false');
            resolve(component);
          })
          .catch(error => {
            Sentry.addBreadcrumb({
              message: `Failed to load chunk ${name}`
            });

            if (!hasRefreshed) {
              window.sessionStorage.setItem(storageName, 'true');
              return window.location.reload();
            }
            reject(error);
          });
      })
  );
}
