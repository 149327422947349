import apiFactory from './api';
import { apiPrefix } from './config';
import { transactionId } from './constants';

export type ApiFactoryOptions = {
  transactionId?: string;
};

const options = {
  transactionId
};

export default apiFactory({ apiPrefix, options });
